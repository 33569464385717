import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    themeColor: '#1890ff',
    roles: [],
    perms: []
  },
  mutations: {
    // 修改token，并将token存入localStorage
    set_token (state, token) {
      state.token = token;
      sessionStorage.token = token
     },
     del_token (state) {
      state.token = '';
      sessionStorage.removeItem('token')
     },
    changeThemeColor (state, val) {
      state.themeColor = val
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMS: (state, perms) => {
      state.perms = perms
    }
  },
  actions: {
  },
  modules: {
  }
})
