<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {

    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store:state')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store:state'))

        )
      )

    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里

    // beforeunload事件在页面刷新时先触发

    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store:state', JSON.stringify(this.$store.state))
    })

  },
  components: {}
}
</script>

<style lang="less">
body,html{
  --themeColor: #1890ff;
}
html,body{
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.el-popup-parent--hidden{
  padding: 0 !important
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
