<template>
  <div class="layoutContainer">
    <div class="tableDetail">
       <el-row>
        <el-col :span="24" style="margin-bottom:10px;">
             <el-select v-model="firmId" @change="firmChanged" >
              <el-option v-for="(item, index) of firmList" :key="index" :label="item.nameCh" :value="item.id"></el-option>
            </el-select>
        </el-col>
       </el-row>
      <el-row>
        <el-col :span="24" style="margin-bottom:20px;">
          <el-button type="primary" :disabled="true" @click="addNode">新增</el-button>
          <el-button type="danger" icon="el-icon-delete" style="background:#FF5722" :disabled="true" @click="delNode">删除</el-button>
          <el-upload
              class="upload-demo inline-block margin-left-10 margin-right-10"
              ref="elUpload"
              action="#"
              :show-file-list="false"
              multiple
              :limit="1"
              :file-list="fileList"
              :before-upload="onBeforeUpload"
              :http-request="uploadFile"
              :on-change="onUploadChange"
            >
            <el-button size="min" icon="el-icon-upload" type="primary">导入</el-button>
          </el-upload>
          <el-button type="primary" icon="el-icon-download" @click="batchExport()">下载</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-tree ref="tree" :data="listdata" :props="defaultProps" 
          node-key="id" :default-expanded-keys="treeExpandIdList"
          @node-click="handleNodeClick"></el-tree>
        </el-col>
        <el-col :span="13" class="fromRight">
          <div>当前目录节点信息</div>
          <el-form ref="form" :model="form">
            <el-form-item label="目录编码" prop="code" :label-width="formLabelWidth">
              <el-input v-model='form.code' placeholder="请输入目录编码"></el-input>
            </el-form-item>
            <el-form-item label="中文名称" prop="cnName" :label-width="formLabelWidth">
              <el-input v-model="form.cnName" placeholder="请输入中文名称"></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="enName" :label-width="formLabelWidth">
              <el-input v-model="form.enName" placeholder="请输入英文名称"></el-input>
            </el-form-item>
            <el-form-item label="目录别名" prop="nickName" :label-width="formLabelWidth">
              <el-input v-model="form.nickName" placeholder="请输入目录别名"></el-input>
            </el-form-item>
            <el-form-item label="url" prop="url" :label-width="formLabelWidth">
              <el-input v-model="form.url" placeholder="请输入url"></el-input>
            </el-form-item>
            <el-form-item label="层级" prop="level" :label-width="formLabelWidth">
              <el-input type="number" min="0" oninput="value=value.replace(/[^\d]/g, '')" v-model="form.level"></el-input>
            </el-form-item>
            <el-form-item label="叶子节点" prop="isLeaf" :label-width="formLabelWidth">
              <el-radio v-model="form.isLeaf" label="1">是</el-radio>
              <el-radio v-model="form.isLeaf" label="0">否</el-radio>
            </el-form-item>
            <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
              <el-input type="number" min="0" oninput="value=value.replace(/[^\d]/g, '')" v-model="form.sort"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
              <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
            <el-form-item class="butArea">
              <el-button type="primary" :disabled="true" @click="updateNode()">保存修改</el-button>
              <el-button :disabled="true" @click="resetForm('form')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
   
    </div>
  </div>
</template>
<script>
import {sysServerUrl,cmsServerUrl } from '@/assets/js/common.js'
import { syllabusData, syllabusFirm, syllabusFile, syllabusEdit, syllabusDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'cnName'
      },
      dialogFormVisible: false,
      form: {
        id: 0,
        pid: 0,
        type: '',
        code: '',
        cnName: '',
        enName: '',
        nickName: '',
        url: '',
        level: 0,
        isLeaf: '',
        sort: 0,
        remark: ''
      },
      listdata: [],
      treeExpandIdList:[],
      trainTypeList: [],
      formLabelWidth: '100px',
      dialogStatus: '',
      uploadUrl: '',
      fileList: [],
      firmList: [],
      firmId: '',
      manualId: 0,
      manualVersion: ''
    }
  },
  watch: {
    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.path !== from.path) {
        // this.manualId = to.params.id
        // this.manualVersion=to.params.version
        // this.dataList()
      }
    }
  },
  methods: {
    dataList () {
      var params = new URLSearchParams()
      params.append('firmId', this.firmId)
      syllabusData(params).then(res => {
        if(res.data.code==100){
          this.listdata = res.data.data
          if(this.listdata!=null&&this.listdata.length>0){
            this.treeExpandIdList.push(this.listdata[0].id)
          }   
        }
      })
      // axios({
      //   method: 'POST',
      //   url: cmsServerUrl + 'cms/manual/directory/getOutlineTreeList',
      //   data: params
      // }).then(res => {
      //   if(res.data.code==100){
      //         this.listdata = res.data.data
      //         if(this.listdata!=null&&this.listdata.length>0){
      //           this.treeExpandIdList.push(this.listdata[0].id)
      //         }
              
      //   }
       
      // })
    },
    // 公司
    getFirmList () {
      syllabusFirm().then(res => {
        if (res.data.code == 100) {
          this.firmList = res.data.data
          this.firmId =this.firmList[0].id
        }
      })
      // axios.get(sysServerUrl + 'sys/car/train/list/firm').then(res => {
      //   if (res.data.code == 100) {
      //     this.firmList = res.data.data
      //     this.firmId =this.firmList[0].id
      //   }
      // })
    },
    firmChanged(value){
      if(value!=''){
        //this.getTrainList(value)
        this.firmId=value
        this.dataList()
      }
    },
      // 附件上传
    onBeforeUpload(file) {
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();  
      var text=""               
      const docExt = fileExt === 'ditamap' 
      const docxExt = fileExt === 'xlsx' 
      const isLimit = file.size / 1024 / 1024 < 2  
      //if(!docExt && !docxExt) { 
      if(!docExt) { 
        text="上次文件只能是 ditamap 格式!";
       
        return false;
      }  
      if (!isLimit) {  
        text="上传文件大小不能超过 2MB!";
       
        return false;
      }  
      return true;
    },
    onUploadChange(file){
      //this.files.push(file.raw) 
      console.log("onUploadChange")
    },
    // 批量上传
    uploadFile (param) {
			  var formData = new FormData(); 
			  formData.append('file', param.file);
        formData.append('firmId', this.firmId)
        syllabusFile(formData).then(res => {
          if (res.data.code === 100) {
            this.$message({
            message: '导入成功',
            type: 'success'
          })
             this.dataList()
          }else{
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        }).catch(function(error){
           this.$message({
              type: 'error',
              message: '上传出现异常，稍后重试'
            })
          if(error.response){
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          }else{
             console.log(error.message)
          }
        })
        // axios({
        //   method: 'post',
        //   url: sysServerUrl + 'cms/manual/directory/importOutline',
        //   data: formData
        // }).then(res => {
        //   if (res.data.code === 100) {
        //     this.$message({
        //     message: '导入成功',
        //     type: 'success'
        //   })
        //      this.dataList()
        //   }else{
        //     this.$message({
        //       type: 'error',
        //       message: res.data.msg
        //     })
        //   }
        // }).catch(function(error){
        //    this.$message({
        //       type: 'error',
        //       message: '上传出现异常，稍后重试'
        //     })
        //   if(error.response){
        //     console.log(error.response.data)
        //     console.log(error.response.status)
        //     console.log(error.response.headers)
        //   }else{
        //      console.log(error.message)
        //   }
        // })
			 
		},
 
    handleNodeClick (data) {
        this.form.type=data.type
      if(this.form.type=='manual'){
          this.resetForm('form')
        }else{
          this.form.id=data.id
          this.form.pid=data.pid
          this.form.type=data.type
          this.form.code = data.code
          this.form.cnName = data.cnName
          this.form.enName = data.enName
          this.form.nickName = data.nickName
          this.form.level = data.level
          this.form.isLeaf = String(data.isLeaf)
          this.form.url = data.url
          this.form.sort = data.sort
          this.form.remark = data.remark
        }
    
    },
    addNode(){
      //判断id是否为0
  			if(this.form.id === 0 ){
          this.$message.error("请选择一个父级节点")
  				return false;
  			}
  			if(this.form.isLeaf == '1'){
          this.$message.error("当前节点为叶子节点不能添加子目录")
  				return false;
  			}

        let directoryId=this.form.id
        this.form.pid=directoryId
        this.form.id=0
        this.form.type='category'
        this.form.code=''

        this.form.cnName=''
        this.form.enName=''
        this.form.nickName=''


        this.form.url=''
        this.form.level=0
        this.form.isLeaf=''
        this.form.sort=0
        this.form.remark=''
       

    },
    updateNode(){
      var params = new URLSearchParams()

      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)

      params.append('id', this.form.id)
      params.append('pid', this.form.pid)
      params.append('code', this.form.code)
      params.append('cnName', this.form.cnName)
      params.append('enName', this.form.enName)
      params.append('nickName', this.form.nickName)

      params.append('url', this.form.url)
      params.append('level', this.form.level)
      params.append('isLeaf', this.form.isLeaf)
      params.append('sort', this.form.sort)
      params.append('remark', this.form.remark)
      syllabusEdit(params).then(res => {
        if (res.data.code === 100) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.dataList()
        } else {
          this.$message.error(res.data.msg)
        }
      })
      // axios({
      //   method: 'post',
      //   url: cmsServerUrl +  "cms/manual/directory/edit",
      //   data: params
      // }).then(res => {
      //   if (res.data.code === 100) {
      //     this.$message({
      //       message: res.data.msg,
      //       type: 'success'
      //     })
      //     this.dataList()
      //   } else {
      //     this.$message.error(res.data.msg)
      //   }
      // })
    },
    // 删除
    delNode () {
        let curId= this.form.id
        let curType= this.form.type
        let nodeName= this.form.cnName

       if(curType=='manual'){
          this.$message.error('目录根节点无法删除')
          return false;
        }
        if(curId==0){
          this.$message.error('请选中需要删除的目录节点')
          return false;
        }

      this.$confirm('确定删除当前目录节点【'+ nodeName+ '】?', '删除目录节点', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        syllabusDel(curId).then(res => {
          if(res.data.code==100){
            this.$message.success('删除成功')
            this.dataList()
            this.resetForm('form')
          }else{
            this.$message.error(res.data.msg)
          }
        })
        // axios.get(cmsServerUrl +"cms/manual/directory/del/"+curId, {
        //   params: {
        //   }
        // }).then(res => {
        //   if(res.data.code==100){
        //     this.$message.success('删除成功')
        //     this.dataList()
        //     this.resetForm('form')
        //   }else{
        //     this.$message.error(res.data.msg)
        //   }
        // })
      }).catch((error)=>{
        this.$message.error('删除失败')
      })
    },
   
    // 重置
    resetForm (form) {
      if (this.$refs[form].resetFields() === undefined) {
        this.$refs[form].resetFields()
        this.form.id=0
        this.form.code=''

        this.form.cnName=''
        this.form.enName=''
        this.form.nickName=''


        this.form.url=''
        this.form.level=0
        this.form.isLeaf=''
        this.form.sort=0
        this.form.remark=''
      }
    }
  },
  mounted () {
    this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=outline'
    this.getFirmList()
     this.dataList()
  }
}
</script>
<style scoped>
  .fromRight{
    border:1px solid #eee;
  }
  .fromRight>div{
    background: #fafafa;
    font-weight: bold;
    font-size: 14px;
    padding: 15px 0;
    text-align: center;
    border-bottom:1px solid #eee;
    box-sizing: border-box;
  }
  .fromRight .el-form-item{
    padding: 10px 15px 10px 0;
    border-bottom:1px solid #eee;
    margin-bottom:0px !important;
    box-sizing: border-box;
  }
</style>
<style>
  .fromRight .butArea .el-form-item__content{
    width: 100% !important;
    display: inline-block !important;
    text-align: center !important;
    margin: 0 auto !important
  }
  .fromRight .el-select{
    width: 100%
  }

  .inline-block{
    display: inline-block
  }
  .margin-right-10{
    margin-right: 10px;
  }
  .margin-left-10{
    margin-left: 10px;
  }
</style>
