<template>
  <div class="layoutContainer">
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="newAdd">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="版本号" prop="version" width="280"></el-table-column>
        <el-table-column label="是否默认版本" prop="isLatest" width="280">
          <template slot-scope="{row}">
            <span v-if="row.isLatest === true">是</span>
            <span v-if="row.isLatest === false">否</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" width="470" prop="remark"></el-table-column>
        <el-table-column label="创建时间" width="280" prop="createdTime" :formatter="dateFormat"></el-table-column>
        <el-table-column label="操作" fixed="right" width="330">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="setDefault(row)">设置默认</el-button>
            <el-button type="primary" size="mini" @click="handelEdit(row)">编辑</el-button>
            <el-button type="primary" size="mini" style="background:#ff5722" @click="handeDel(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="版本号" porp="version" :label-width="formLabelWidth">
            <el-input v-model="temp.version" placeholder="请输入版本号"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model="temp.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('temp') : editClick('temp')">立即提交</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 手册管理-手册目录-增加接口有问题(接口是编辑的接口)
import { cmsServerUrl } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { versionData, versionAdd, versionEdit, versionDefault, versionDel } from '@/api/api.js';
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      manualId: '',
      resultList: [],
      temp: {
        id: '',
        mId: '',
        version: '',
        remark: ''
      },
      textMap: {
        edit: '编辑',
        add: '新增版本'
      },
      dialogFormVisible: false,
      dialogStatus: '',
      formLabelWidth: '100px',
      pagesize: 10,
      currentPage: 1,
      total: 0,
      rules: {
        version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      }
    }
  },
  methods: {
    dataList () {
      var params = '?manualId=' + this.manualId
      versionData(params).then(res => {
        this.resultList = res.data.data
        this.total = res.data.total
      })
 
    },
    resetTemp () {
      this.temp = {
        version: '',
        remark: ''
      }
    },
    // 新增
    newAdd () {
      this.resetTemp()
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          if (this.temp.id === undefined) {
            params.append('id', '0')
          } else {
            params.append('id', '')
          }
          if (this.temp.mId === undefined) {
            params.append('manualId', '')
          } else {
            params.append('manualId', this.temp.mId)
          }
          params.append('version', this.temp.version)
          params.append('remark', this.temp.remark)
          versionAdd(params).then(res =>{
            if (res.data.code === 100) {
              this.$message({
                message: '新增成功!',
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error('提交失败，请重试')
            }
          })
          // axios({
          //   method: 'post',
          //   url: cmsServerUrl + 'cms/manual/version/edit',
          //   data: params,
          //   headers: { Authorization: sessionStorage.token }
          // }).then(res => {
          //   if (res.data.code === 100) {
          //     this.$message({
          //       message: '新增成功!',
          //       type: 'success'
          //     })
          //     this.dataList()
          //     this.dialogFormVisible = false
          //   } else {
          //     this.$message.error('提交失败，请重试')
          //   }
          // })
        } else {
          this.$message.error('完善信息')
        }
      })
    },
    // 编辑
    handelEdit (row) {
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
      this.temp = row
    },
    editClick (temp) {
      this.$refs[temp].validate((valid) => {
        if(valid){
          var params = new URLSearchParams()
          if (this.temp.mId === undefined) {
            params.append('manualId', '')
          } else {
            params.append('manualId', this.temp.mId)
          }
          params.append('id', this.temp.id)
          params.append('version', this.temp.version)
          params.append('remark', this.temp.remark)
          versionAdd(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error('提交失败，请重试')
            }
          })
        } else {
          this.$message.error('完善信息')
        }
      })
    },
    // 重置
    resetForm (temp) {
      if (this.$refs[temp].resetFields() !== undefined) {
        this.$refs[temp].resetFields()
      }
    },
    // 默认版本
    setDefault (row) {
      this.$confirm('确定设置当前版本为默认版本吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var params = new URLSearchParams()
        params.append('id', row.id)
        params.append('manualId', row.manualId)
        versionDefault(params).then(res => {
          if (res.data.code === 100) {
            this.$message({
              message: '设置默认版本成功!',
              type: 'success'
            })
            this.dataList()
          } else {
            this.$message.error('设置默认版本版本')
          }
        })
      }).catch((error)=>{
        this.$message.error('删除失败')
      })
    },
    // 删除
    handeDel (row) {
      this.$confirm('确定删除 ' + row.name + '?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        versionDel(row.id).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.dataList()
          }
        })
      }).catch((error)=>{
        this.$message.error('删除失败')
      })
    },
    // 时间转换
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.manualId = this.$route.params.id
    this.dataList()
  }
}
</script>
