<template>
  <div class="layoutContainer">
    <div class="tableDetail">
      <el-row>
        <el-col :span="24" style="margin-bottom:20px;">
          <el-button type="primary" @click="auditPass">审核通过</el-button>
          <el-button type="primary" @click="showRejectDlg">审核驳回</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-tree ref="tree" :data="listdata" :props="defaultProps"
           node-key="id" :default-expanded-keys="treeExpandIdList"
           show-checkbox
           highlight-current
           :check-strictly="false" 
           :render-content="renderContent"
           @node-click="handleNodeClick"></el-tree>
        </el-col>
        <el-col :span="13" class="formRight">
          <div>当前节点信息</div>
          <el-form ref="form" :model="form">
            <el-form-item label="id" prop="id" :label-width="formLabelWidth">
              <el-input v-model="form.id"></el-input>
            </el-form-item>
            <el-form-item label="pid" prop="pid" :label-width="formLabelWidth">
              <el-input v-model="form.pid"></el-input>
            </el-form-item>
            <el-form-item label="manualId" prop="manualId" :label-width="formLabelWidth">
              <el-input v-model="form.manualId"></el-input>
            </el-form-item>
            <el-form-item label="manualVersion" prop="manualVersion" :label-width="formLabelWidth">
              <el-input v-model="form.manualVersion"></el-input>
            </el-form-item>
            <el-form-item label="审核状态:" prop="state" :label-width="formLabelWidth">
              <el-input v-model="form.state"></el-input>
            </el-form-item>
            <el-form-item label="展示效果:" prop="effect" :label-width="formLabelWidth">
              <el-input v-model="form.effect"></el-input>
            </el-form-item>
            <el-form-item class="butArea">
              <el-button type="primary" @click="update()">保存</el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-dialog title="审核驳回"  :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form  ref='dataForm' label-position="center">
        <el-form-item label="驳回原因:" prop="rejectReason" :label-width="formLabelWidth">
          <el-input type="textarea"  rows="3" v-model="rejectReason" aria-required="true"></el-input>
        </el-form-item>
         <el-form-item label="附件" prop="rejectAttach" :label-width="formLabelWidth">
              <el-upload
                class="upload-demo"
                style="max-width: 379px;"
                :action="uploadUrl"
                :headers="importHeader"
                :on-success="handleOnSuccess"
                :on-remove="handleOnRemove"
                :before-remove="beforeOnRemove"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleOnExceed"
                 multiple
                :limit="1"
                :file-list="fileList"
                 accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg,.DOC,.doc,.DOCX,.docx"
              >
                <el-button size="min" icon="el-icon-upload" type="primary">点击上传</el-button>
              </el-upload>
          </el-form-item>
        <el-form-item :label-width="formLabelWidth" style=" text-align:center;">
          <el-button type="primary" @click="auditReject" >
            立即提交
          </el-button>
          <el-button @click="dialogFormVisible = false" >
            取消
          </el-button>
         
        </el-form-item>
      </el-form>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl } from '@/assets/js/common.js'
import { auditManualData, auditPass, auditReject } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'cnName'
      },
      form: {
        id: '',
        pid: '',
        manualId: '',
        manualVersion: '',
        state: '',
        effect: ''
      },
      formLabelWidth: '100px',
      manualId: 0,
      manualVersion: '',
      treeExpandIdList:[],
      listdata: [],
      dialogFormVisible: false,
      uploadUrl: '',
      rejectReason: '',
      fileList: []
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token }
    }
  },
   watch: {
    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.path !== from.path) {
        this.dataList()
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = new URLSearchParams()
      params.append('id', this.$route.params.id)
      params.append('manualVersion', this.$route.params.version)
      auditManualData(params).then(res=>{
        this.listdata = res.data.data
        this.treeExpandIdList.push(this.listdata[0].id)
      })
    },
    renderContent(h, { node, data, store }) {
      let isLeaf = data.isLeaf;
      let  vStatus= data.auditStatus==null?0:data.auditStatus;
      let style=''
      if(isLeaf==1){
	  	    if(vStatus==2){
	  	    	style='red-category'
	  	    }else if(vStatus==1){
             style='green-category'
	  	    }else{
               style='blue-category'
	  	    }
  		  }else{
           style='black-category'
  		  }
      
      return(<span class={style} style="font-size:16px;cursor:pointer">{node.label}</span>)
    },
    // 审核通过
    auditPass() {
      var checkedNodes = this.$refs.tree.getCheckedNodes();
      if(checkedNodes.length==0){
        this.$message.error('请先勾选目录节点')
        return false
      }
		  var chkIds="";
			for(var i=0;i<checkedNodes.length;i++){
				if(checkedNodes[i].isLeaf==1){
					chkIds+=checkedNodes[i].id + ",";
				}
		  }
      var params = new URLSearchParams()
      params.append('directoryIds', chkIds)
      params.append('manualId', this.manualId)
      params.append('manualVersion',this.manualVersion)
      auditPass(params).then(res => {
        if(res.data.code==100){
          this.$message.success('审核通过成功')
          this.dataList()
        }
      }).catch(function(error){
        this.$message.error('审核提交失败')
      })
    },
    showRejectDlg(){
       this.rejectReason=''
       this.fileList=[]
       var checkedNodes = this.$refs.tree.getCheckedNodes();
        if(checkedNodes.length==0){
            this.$message.error('请先勾选目录节点')
            return false
        }
      this.dialogFormVisible=true
    },
    // 审核驳回
    auditReject() {
      if(this.rejectReason==''){
        this.$message.error('请输入驳回原因')
        return false
      }
      var checkedNodes = this.$refs.tree.getCheckedNodes();
		  var chkIds="";
			for(var i=0;i<checkedNodes.length;i++){
			 	if(checkedNodes[i].isLeaf==1){
			 	  chkIds+=checkedNodes[i].id + ",";
			 	}
		  }  
      var params = new URLSearchParams()
      params.append('directoryIds', chkIds)
      params.append('manualId',this.manualId)
      params.append('reason', this.rejectReason)
      params.append('manualVersion',this.manualVersion)
      let fileName=''
      let filePath=''
      if(this.fileList!=null&&this.fileList.length>0){
        fileName =this.fileList[0].name
        filePath =this.fileList[0].url
      }
      params.append('fileName', fileName)
      params.append('path',filePath)
      auditReject(params).then(res => {
        if(res.data.code==100){
          this.$message.success('审核驳回提交成功')
          this.dataList()
          this.dialogFormVisible=false
        }
      }).catch(function(error){
        this.$message.error('审核驳回提交失败')
      })
    },
    handleOnSuccess (res, obj) {
      //this.dataForm.imgName = res.data.fileName
      //this.dataForm.image = res.data.fileUrl
        var file = {name: res.data.fileName, url: res.data.fileUrl}
        this.fileList.push(file)
      
    },
    beforeOnRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleOnRemove(file,fileList){
       console.log(file)
      console.log(fileList)
    },
    beforeAvatarUpload (file) {
      const extension = file.name.split('.')[1] === 'png'
      const extension2 = file.name.split('.')[1] === 'jpg'
      const extension3 = file.name.split('.')[1] === 'jpeg'
      const extension4 = file.name.split('.')[1] === 'gif'
      const extension5 = file.name.split('.')[1] === 'doc'
      const extension6 = file.name.split('.')[1] === 'docx'
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!extension && !extension2 && !extension3 && !extension4 && !extension5 && !extension6) {
        this.$message.warning('上传模板只能是 png、jpg、jpeg、gif、doc、docx格式!')
      }
      if (!isLt2M) {
        this.$message.warning('上传模板大小不能超过 5MB!')
      }
    },
    handleOnExceed (files, fileList) {
      this.$message.warning(`当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // 显示详情
    handleNodeClick (data) {
      this.form.id = data.id
      this.form.pid = data.pid
      this.form.manualId = data.manualId
      this.form.manualVersion = data.manualVersion
      if (data.isLeaf === 1) {
        if (data.auditStatus === 1) {
          this.form.state = '审核通过'
        } else if (data.auditStatus === 2) {
          this.form.state = '审核驳回'
        } else {
          this.form.state = '未审核'
        }
      } else {
        this.form.state = ''
      }
      // var level = data.level
      // var directoryId = data.directoryId
      // var isLeaf = data.isLeaf
      // var vStatus = treeNode.auditStatus
      // if (level === 0) {
      // } else {}
      // console.log(data.level)
    },
    // 提交
    update () {
    },
    // 重置
    resetForm () {
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    this.manualId = this.$route.params.id
    this.manualVersion=this.$route.params.version
    this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=ManualReason'
    this.dataList()
  }
}
</script>
<style scoped>
  .formRight{
    border: 1px solid #eee;
  }
  .formRight>div{
    background: #fafafa;
    font-weight: bold;
    font-size: 14px;
    padding: 15px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
  }
  .formRight .el-form-item{
    padding: 10px 15px 10px 0;
    border-bottom: 1px solid #eee;
    margin-bottom:0px !important;
    box-sizing: border-box;
  }
</style>
<style>
  .formRight .butArea .el-form-item__content{
    width: 100% !important;
    display: inline-block !important;
    text-align: center !important;
    margin: 0 auto !important;
  }
</style>
<style>
  .red-category{
    color:red;
  }
  .green-category{
    color:green;
  }
  .blue-category{
    color:blue
  }
  .black-category{
    color:black
  }
  .el-upload-list__item {
   transition: none !important;
  }
</style>
