<template>
  <div class="login">
    <div style="float:right;margin: 20px 20px 0 0;width:100px">
      <el-select v-model="selectValue" @change="langChange">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <el-form rel="loginForm" :model="loginform" label-width="120px" :rules="loginrules" class="login-form" autocomplete="on" label-position="left">
      <p class="login-title">{{$t('login.title')}}</p>
      <el-form-item :label="$t('login.userName')" prop="username">
        <el-input type="text" :placeholder="$t('login.userInput')" v-model="loginform.username"></el-input>
      </el-form-item>
      <el-form-item :label="$t('login.password')" prop="password">
        <el-input type="password" :placeholder="$t('login.passInput')" v-model="loginform.password"></el-input>
      </el-form-item>
      <el-form-item :label="$t('login.code')" class="captCha" prop="captCha">
        <el-input type="text" :placeholder="$t('login.codeInput')" v-model="loginform.captCha"></el-input>
        <img @click="getCaptCha" :src="loginform.src" class="CaptChaImg">
        <input type="hidden" v-model="loginform.id">
      </el-form-item>
      <el-form-item @keyup.enter.native="login">
        <el-button class="button" @click="login" type="primary">{{$t('login.button')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// import { sysServerUrl, cmsServerUrl } from '../../assets/js/common'
// import axios from 'axios'
import { onLogin, currentUser,captCha } from '@/api/api.js'
export default {
  name: 'Login',
  data () {
    return {
      selectValue: '',
      options: [
        {
          value: 'cn',
          label: '中文'
        },
        {
          value: 'en',
          label: 'English'
        }
      ],
      loginform: {
        username: '',
        password: '',
        captCha: '',
        id: null,
        src: ''
      },
      // 表单验证，需要在el-form-item元素中增加prop属性
      loginrules: {
        username: [
          { required: true, message: this.$t('login.userTip'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('login.passTip'), trigger: 'blur' }
        ],
        captCha: [
          { required: true, message: this.$t('login.codeTip'), trigger: 'blur' }
        ]
      },
      // 对话框显示和隐藏
      dialogVisible: false
    }
  },
  created () {
    var that = this
    that.selectValue = localStorage.lang === undefined ? 'cn' : localStorage.lang
    localStorage.lang = that.selectValue
  },
  methods: { 
    // 语言切换
    langChange (e) {
      localStorage.setItem('lang', e)
      this.$i18n.locale = e
      location.reload()
    },
     getUserInfo(){
      var params = new URLSearchParams()
      params.append('all', "1")
       var _this =this
          currentUser(params).then(res => {
            if(res.data.code ==100){
             _this.$store.commit('SET_ROLES', res.data.data.roleList)
             _this.$store.commit('SET_PERMS', res.data.data.permissionList)
             _this.$router.push('/Index')
            }
          
          }).catch(err => {
           console.log(err)
          if(err != null && err !== '' && err.responseText !== null){
            _this.$message.error('系统登录异常')
          }
      })
    },
    // login123 () {
    //     var _this =this
		// 	  var params = new FormData(); 
		// 	 var params = {
    //     username: _this.loginform.username,
    //     password: _this.loginform. password,
    //     vid: _this.loginform.id,
    //     verifycode: _this.loginform.captCha,
    //     type: '1'
    //   }
    //     axios({
    //       method: 'post',
    //       url: sysServerUrl + 'simgen-sysmgt/sys/user/login',
    //       data: params,
    //       headers:{
    //         'Content-Type':'application/json' 
    //       }
    //     }).then(res => {
    //     if(res.data.code === 100){
    //       var tokenVal = res.data.data.tokenHead + res.data.data.token
    //       this.$store.commit('set_token', tokenVal)
    //       this.getUserInfo()  
    //       //_this.$router.push('/Index')
    //     }else if(res.data.code === 1011){
    //       _this.$message.error(res.data.msg)
    //     }else if(res.data.code === 1012){
    //       _this.$message.error(res.data.msg)
    //     }else if(res.data.code === 400){
    //       _this.$message.error(res.data.msg)
    //     }else if(res.data.code === 101){
    //       _this.$message.error(res.data.msg)
    //     }else {
    //        _this.$message.error('系统登录失败')
    //     }
         
    //     }).catch(function(error){
    //        console.log(error)
    //        console.log(error.message)
        
    //        _this.$alert('系统出现异常，导入失败','信息提示',{dangerouslyUseHTMLString:true})
           
    //     })
			 
		// },
    login () {
      var _this = this
      if (!_this.loginform.username) {
        _this.$message.error(_this.$t('login.userTip'))
        return
      }
      if (!_this.loginform.password) {
        _this.$message.error(_this.$t('login.passTip'))
        return
      }
      if (!_this.loginform.captCha) {
        _this.$message.error(_this.$t('login.codeTip'))
        return
      }
      var params = {
        username: _this.loginform.username,
        password: _this.loginform. password,
        vid: _this.loginform.id,
        verifycode: _this.loginform.captCha,
        type: '1'
      }
      onLogin(params).then(res =>{
        if(res.data.code === 100){
          var tokenVal = res.data.data.tokenHead + res.data.data.token
          this.$store.commit('set_token', tokenVal)
          this.getUserInfo()  
          //_this.$router.push('/Index')
        }else if(res.data.code === 1011){
          _this.$message.error(res.data.msg)
        }else if(res.data.code === 1012){
          _this.$message.error(res.data.msg)
        }else if(res.data.code === 400){
          _this.$message.error(res.data.msg)
        }else if(res.data.code === 101){
          _this.$message.error(res.data.msg)
        }else {
           _this.$message.error('系统登录失败')
        }
      }).catch(err => {
        if(err != null && err !== '' && err.responseText !== null){
          _this.$message.error('系统登录异常')
        }
      })
    },
    keyDown (e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    getCaptCha () {
      captCha().then(res=>{
        if (res.data.code === 100) {
          this.loginform.id = res.data.data.id
          this.loginform.src = res.data.data.image
        }
      })
    }
  },
  mounted () {
    this.getCaptCha()
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed () {
    window.removeEventListener('keydown', this.keyDown, false)
  }
}
</script>
<style scoped>
  .login{
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: url('../../assets/image/login.png') no-repeat;
    background-size:cover;
    position: relative;
  }
  .login-form{
    width: 500px;
    position: absolute;
    right: 22%;
    top: 26%
  }
  .login-form .login-title{
    font-size: 32px;
    color: #1950a0;
    margin-bottom: 40px;
    text-align: center;
  }
  /* 验证码 */
  .CaptChaImg{
    width: 80px;
    height: 45px;
    margin-left:20px;
  }
</style>
<style>
  .el-form-item__content{
    display: flex;
  }
  /* 输入框 */
  .login-form .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 20px !important;
    color: #333 !important;
    line-height: 45px;
    padding: 0 12px 0 0;
    white-space: nowrap;
    text-align-last: justify;
  }
  /* .login-form .el-form--label-left .el-form-item__label{
    text-align: right !important;
  }
  .el-form--label-left .el-form-item__label{
    text-align: right !important;
  } */
  .login-form .el-input__inner {
    -webkit-appearance: none;
    background-color: rgba(255,255,255,0.5) !important;
    background-image: none;
    border-radius: 2px !important;
    border: 1px solid #eee !important;
    height: 45px !important;
    line-height: 45px !important;
    color: #333 !important;
    font-size: 16px;
  }
  .login-form .el-input__inner::placeholder {
    color: rgb(117,117,117) !important;
  }
  /* 登录按钮 */
  .login-form .el-button{
    font-size: 17px !important;
    letter-spacing: 5px;
    width: 100%
  }
  .login-form .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
  .login-form .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: '*';
    color: transparent !important;
    margin-right: 4px;
  }
  /* .el-message.el-message--error{
    margin-top: 10%
  } */
</style>
