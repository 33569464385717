<template>
  <div class="layoutContainer">
    <div class="tableDetail">
      <el-row>
        <el-col :span="24" style="margin-bottom:20px;">
          <el-button type="primary" @click="addNode">新增</el-button>
          <el-button type="danger" icon="el-icon-delete" style="background:#FF5722" @click="delNode">删除</el-button>
          <el-button type="primary" @click="ShowImportDlg">批量导入数据包</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-tree ref="tree" :data="listdata" :props="defaultProps" 
          node-key="id" :default-expanded-keys="treeExpandIdList"
          @node-click="handleNodeClick"></el-tree>
        </el-col>
        <el-col :span="13" class="fromRight">
          <div>当前目录节点信息</div>
          <el-form ref="form" :model="form">
            <el-form-item label="目录编码" prop="code" :label-width="formLabelWidth">
              <el-input v-model='form.code' placeholder="请输入目录编码"></el-input>
            </el-form-item>
            <el-form-item label="中文名称" prop="cnName" :label-width="formLabelWidth">
              <el-input v-model="form.cnName" placeholder="请输入中文名称"></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="enName" :label-width="formLabelWidth">
              <el-input v-model="form.enName" placeholder="请输入英文名称"></el-input>
            </el-form-item>
            <el-form-item label="目录别名" prop="nickName" :label-width="formLabelWidth">
              <el-input v-model="form.nickName" placeholder="请输入目录别名"></el-input>
            </el-form-item>
            <el-form-item label="url" prop="url" :label-width="formLabelWidth">
              <el-input v-model="form.url" placeholder="请输入url"></el-input>
            </el-form-item>
            <el-form-item label="层级" prop="level" :label-width="formLabelWidth">
              <el-input type="number" min="0" oninput="value=value.replace(/[^\d]/g, '')" v-model="form.level" ></el-input>
            </el-form-item>
            <el-form-item label="叶子节点" prop="isLeaf" :label-width="formLabelWidth">
              <el-radio v-model="form.isLeaf" label="1">是</el-radio>
              <el-radio v-model="form.isLeaf" label="0">否</el-radio>
            </el-form-item>
            <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
              <el-input type="number" min="0" oninput="value=value.replace(/[^\d]/g, '')" v-model="form.sort"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
              <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
            <el-form-item class="butArea">
              <el-button type="primary" @click="updateNode()">保存修改</el-button>
              <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-dialog title="批量上传"  v-if="dialogStatus === 'upload'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <div class="upload-area">
      <el-upload
            class="upload-demo inline-block margin-left-10 margin-right-10"
            ref="elUpload"
            action="#"
            :show-file-list="false"
            multiple
            :limit="1"
            :before-upload="onBeforeUpload"
         
            >
               <!-- :http-request="uploadFile" -->
          <el-button size="min" icon="el-icon-upload" type="primary" style="" v-show="showUploadBtn">批量上传</el-button>
     
          <el-progress style="width:350px;margin-top:100px;margin-left:10px"  v-show="showUploadProcess" color="green" type="line" :text-inside="true" :stroke-width="20" :percentage="percentage"></el-progress>
        </el-upload>
       
     </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl,cmsServerUrl } from '@/assets/js/common.js'
import { catalogData, catalogFile, catalogCheck, catalogImport, catalogEdit, catalogDel } from '@/api/api.js'
import axios from 'axios'
import SparkMD5 from 'spark-md5'

/** 计算文件md5值
 * @param file 文件
 * @param chunkSize 分片大小
 * @returns Promise
 */
function getmd5(file, chunkSize) {
    return new Promise((resolve, reject) => {
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        let chunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let spark = new SparkMD5.ArrayBuffer();
        let fileReader = new FileReader();
        fileReader.onload = function(e) {
            spark.append(e.target.result);
            currentChunk++;
            if (currentChunk < chunks) {
                loadNext();
            } else {
                let md5 = spark.end();
                resolve(md5);
                //  console.log(md5);
            }
        };
        fileReader.onerror = function(e) {
            reject(e);
        };
        function loadNext() {
            let start = currentChunk * chunkSize;
            let end = start + chunkSize;
            if (end > file.size){
                end = file.size;
            }
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        loadNext();
    });
}
export default {
  name: 'layoutContainer',
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'cnName'
      },
      dialogFormVisible: false,
      form: {
        id: 0,
        pid: 0,
        type: '',
        code: '',
        cnName: '',
        enName: '',
        nickName: '',
        url: '',
        level: 0,
        isLeaf: '',
        sort: 0,
        remark: ''
      },
      listdata: [],
      treeExpandIdList:[],
      trainTypeList: [],
      formLabelWidth: '100px',
      dialogStatus: '',
      uploadUrl: '',
      fileList: [],
      //切片文件
      fileShard:{},
      //当前文件
      curFile:{},
        //文件分割的开始位置
      start: 0,
      //文件分割的结束位置
      end: 0,
      //文件大小
      fileSize: 0,
      fileKey: '',
      fileShardIndex: 0,
      fileShardTotal: 0,
      fileShardSize: 0,
      switchC: false,
      percentage: 0,
      showUploadBtn:false,
      showUploadProcess:false,
      manualId: 0,
      manualVersion: ''
    }
  },
  watch: {
    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.path !== from.path) {
        this.manualId = to.params.id
        this.manualVersion=to.params.manualVersion
        this.dataList()
      }
    }
  },
  methods: {
    dataList () {
      var params = new URLSearchParams()
      params.append('id', this.$route.params.id)
      params.append('manualVersion', this.$route.params.manualVersion)
      catalogData(params).then(res => {
        console.log(res)
        if(res.data.code==100){
          this.listdata = res.data.data
          this.treeExpandIdList.push(this.listdata[0].id)
        }
      })
      // axios({
      //   method: 'POST',
      //   url: cmsServerUrl + 'cms/manual/directory/getChildrenList',
      //   data: params
      // }).then(res => {
      //   if(res.data.code==100){
      //         this.listdata = res.data.data
      //         this.treeExpandIdList.push(this.listdata[0].id)
      //   }
       
      // })
    },
    ShowImportDlg(){
      this.showUploadBtn=true
      this.percentage=0;
      this.dialogStatus = 'upload'
      this.dialogFormVisible = true
    },
    onBeforeUpload(file){
        let text=""
        var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();              
        const zipExt = fileExt === 'zip' 
        const isLimit = file.size / 1024 / 1024 < 1024
        if(!zipExt) { 
        text="上传文件只能是 zip格式!";
        this.$message.error(text)
        return false;
        }  
        if (!isLimit) {  
        text="上传文件大小不能超过 1GB!";
        this.$message.error(text)
        return false;
        } 
        this.fileShardSize = 1*1024 * 1024; //每片文件大小

        //点击后隐藏上传按钮 ，防止重复点击
        //$("#fileUpload").css('visibility','hidden');
        this.showUploadBtn=false
        this.showUploadProcess=true
        var _this=this
        getmd5(file,_this.fileShardSize).then(e =>{
                _this.switchC=false;
                _this.fileShardIndex=1;//分片索引
                _this.curFile=file;
                _this.fileKey=e;
                _this.fileSize=file.size;
                _this.fileShardTotal=Math.ceil(file.size/_this.fileShardSize);//分片总数
                var fileFullName=file.name;
                _this.fileSuffix = fileFullName.substr(fileFullName.lastIndexOf('.') + 1);
                _this.fileName = fileFullName.substr(0, fileFullName.lastIndexOf('.'));

                //上传参数
                var params =  new FormData()
                params.append('fileName', _this.fileName)
                params.append('fileShardTotal', _this.fileShardTotal)
                params.append('fileKey', _this.fileKey)
                params.append('fileSuffix', _this.fileSuffix)
                params.append('fileShardSize', _this.fileShardSize)
                params.append('fileSize', _this.fileSize)
                params.append('fileFlag', "manual")

                _this.updateProgress(file,params)


            })
        

    },
    // 批量上传
    uploadFile (formData) {
      var _this=this
      catalogFile(formData).then(res => {
        if(res.data.code==200){
          //上传分片完成
          if(res.data.shardIndex<_this.fileShardTotal){
            _this.fileShardIndex=_this.fileShardIndex+1;
            _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
            _this.end =Math.min(_this.curFile.size,_this.start+_this.fileShardSize);
            _this.fileSize=_this.curFile.size;
            var params =  new FormData()
            params.append('fileName', _this.fileName)
            params.append('fileShardTotal', _this.fileShardTotal)
            params.append('fileKey', _this.fileKey)
            params.append('fileSuffix', _this.fileSuffix)
            params.append('fileShardSize', _this.fileShardSize)
            params.append('fileSize', _this.fileSize)
            params.append('fileFlag', "manual")
            params.append('fileShardIndex', _this.fileShardIndex)
            var fileShardtem=_this.curFile.slice(_this.start,_this.end);//从文件中获取当前分片数据
            let fileReader = new FileReader();
            //异步读取本地文件分片数据并转化为base64字符串
            fileReader.readAsDataURL(fileShardtem); 
            //本地异步读取成功后，进行上传
            fileReader.onload = function (e) {
              let  base64str = e.target.result;
              params.append('base64', base64str)
              _this.uploadFile(params)
            }    
            let perentNum=Math.ceil(this.fileShardIndex * 100 / this.fileShardTotal) 
            if(perentNum>100){
              this.percentage=100
            }else{
              this.percentage=perentNum
            }
          }
        }else if(res.data.code==100){
          var fileId= res.data.id
          //上传完成
          _this.percentage=100
          _this.switchC=true
      
          // this.$message({
          //   message: '极速上传成功',
          //   type: 'success'
          // })
          _this.batchImport(fileId)          
        }
        console.log(this.percentage)
      }).catch((error)=>{
        if(error.response){
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        }else{
          console.log(error.message)
        }
      })
        // axios({
        //     method: 'post',
        //     url: sysServerUrl+"sys/upload/procFile",
        //     data: formData
        // }).then(res => {
        //         if(res.data.code==200){
        //         	//上传分片完成
        //             if(res.data.shardIndex<_this.fileShardTotal){
        //                 _this.fileShardIndex=_this.fileShardIndex+1;
        //                 _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
        //                 _this.end =Math.min(_this.curFile.size,_this.start+_this.fileShardSize);
        //                 _this.fileSize=_this.curFile.size;

        //                   var params =  new FormData()
        //                     params.append('fileName', _this.fileName)
        //                     params.append('fileShardTotal', _this.fileShardTotal)
        //                     params.append('fileKey', _this.fileKey)
        //                     params.append('fileSuffix', _this.fileSuffix)
        //                     params.append('fileShardSize', _this.fileShardSize)
        //                     params.append('fileSize', _this.fileSize)
        //                     params.append('fileFlag', "manual")
                         
        //                 params.append('fileShardIndex', _this.fileShardIndex)
                      
        //                 var fileShardtem=_this.curFile.slice(_this.start,_this.end);//从文件中获取当前分片数据
        //                     let fileReader = new FileReader();
        //                     //异步读取本地文件分片数据并转化为base64字符串
        //                     fileReader.readAsDataURL(fileShardtem); 
        //                     //本地异步读取成功后，进行上传
        //                     fileReader.onload = function (e) {
        //                       let  base64str = e.target.result;
        //                        params.append('base64', base64str)
        //                       _this.uploadFile(params)
                      
        //                     }
                            
        //                 let perentNum=Math.ceil(this.fileShardIndex * 100 / this.fileShardTotal) 
        //                 if(perentNum>100){
        //                   this.percentage=100
        //                 }else{
        //                    this.percentage=perentNum
        //                 }
        //             }
        //         }else if(res.data.code==100){
        //           var fileId= res.data.id
        //             //上传完成
        //             _this.percentage=100
        //             _this.switchC=true
              
        //             // this.$message({
        //             //   message: '极速上传成功',
        //             //   type: 'success'
        //             // })
        //             _this.batchImport(fileId)
                       
        //         }
        //   console.log(this.percentage)
        // }).catch(function(error){
        //     if(error.response){
        //     console.log(error.response.data)
        //     console.log(error.response.status)
        //     console.log(error.response.headers)
        //     }else{
        //         console.log(error.message)
        //     }
        // })
            
    },
    updateProgress(file,params){
      var _this= this
      var param = new URLSearchParams()
      param.append('shardKey', _this.fileKey)
      catalogCheck(param).then(res => {
        if(res.data.code==200){
          //新文件
          _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
          _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
          _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        }else if(res.data.code==220){
          _this.fileShardIndex=res.data.ShardIndex;
          //有上传未完成的
          _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
          _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
          _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        }else if (res.data.code==240){
          //急速上传
          var fileId= res.data.id
          _this.percentage=100
          _this.switchC=true
          console.log(this.percentage)
          // this.$message({
          //   message: '极速上传成功',
          //   type: 'success'
          // })
          _this.batchImport(fileId)
          return false;
        }
        //读取base64str
        let fileReader = new FileReader();
        //异步读取本地文件分片并转化为base64字符串
        fileReader.readAsDataURL(_this.fileShard); 
        //本地异步读取成功，进行上传
        fileReader.onload=function (e){
          let  base64str=e.target.result;
          params.append('base64', base64str)
          params.append('fileShardIndex', _this.fileShardIndex)
          if(_this.switchC==false){
              _this.uploadFile(params)
          }
        }
      }).catch((error)=>{
        this.$message.error('上传错误')
      })
        // axios({
        // method: 'POST',
        // url:  sysServerUrl+"sys/upload/checkProgress",
        // data: param
        // }).then(res => {
           
        //     if(res.data.code==200){
        //         //新文件
        //         _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
        //         _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
        //         _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据

        //     }else if(res.data.code==220){
        //         _this.fileShardIndex=res.data.ShardIndex;
        //         //有上传未完成的
        //         _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
        //         _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
        //         _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        //     }else if (res.data.code==240){
        //         //急速上传
           
        //       var fileId= res.data.id
        //         _this.percentage=100
        //         _this.switchC=true
        //          console.log(this.percentage)

        //         // this.$message({
        //         //   message: '极速上传成功',
        //         //   type: 'success'
        //         // })

        //         _this.batchImport(fileId)

        //         return false;
        //     }
            
        //     //读取base64str
        //     let fileReader = new FileReader();
        //     //异步读取本地文件分片并转化为base64字符串
        //     fileReader.readAsDataURL(_this.fileShard); 
        //     //本地异步读取成功，进行上传
        //     fileReader.onload=function (e){
        //         let  base64str=e.target.result;
        //         params.append('base64', base64str)
        //         params.append('fileShardIndex', _this.fileShardIndex)
        //         if(_this.switchC==false){
        //             _this.uploadFile(params)
        //         }
        //     }
        // })
    },
    batchImport(fileId){
      var params = {
        manualId: this.manualId,
        fileId: fileId
      }
      catalogImport(params).then(res => {
        if(res.data.code==100){
          this.dialogFormVisible = false
          this.$message({
            duration:10000,
            message: '上传已完成,系统正在解析处理数据，请稍等...',
            type: 'success'
          })
          this.dataList()
        }
      })
        // axios.get(cmsServerUrl +"cms/manual/directory/batchImport", {
        //     params: {
        //     manualId: this.manualId,
        //     fileId: fileId
        //     }
        // }).then(res => {
        //     if(res.data.code==100){
        //       this.dialogFormVisible = false
        //       this.$message({
        //               duration:10000,
        //               message: '上传已完成,系统正在解析处理数据，请稍等...',
        //               type: 'success'
        //       })
        //       this.dataList()
        //     }
          
        // })
    },
    handleNodeClick (data) {
        this.form.type=data.type
      if(this.form.type=='manual'){
          this.resetForm('form')
        }else{
          this.form.id=data.id
          this.form.pid=data.pid
          this.form.type=data.type
          this.form.code = data.code
          this.form.cnName = data.cnName
          this.form.enName = data.enName
          this.form.nickName = data.nickName
          this.form.level = data.level
          this.form.isLeaf = String(data.isLeaf)
          this.form.url = data.url
          this.form.sort = data.sort
          this.form.remark = data.remark
        }
    
    },
    addNode(){
      //判断id是否为0
  			if(this.form.id === 0 ){
          this.$message.error("请选择一个父级节点")
  				return false;
  			}
  			if(this.form.isLeaf == '1'){
          this.$message.error("当前节点为叶子节点不能添加子目录")
  				return false;
  			}

        let directoryId=this.form.id
        this.form.pid=directoryId
        this.form.id=0
        this.form.type='category'
        this.form.code=''

        this.form.cnName=''
        this.form.enName=''
        this.form.nickName=''

        this.form.url=''
        this.form.level=0
        this.form.isLeaf=''
        this.form.sort=0
        this.form.remark=''
       

    },
    updateNode(){
      var params = new URLSearchParams()

      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)

      params.append('id', this.form.id)
      params.append('pid', this.form.pid)
      params.append('code', this.form.code)
      params.append('cnName', this.form.cnName)
      params.append('enName', this.form.enName)
      params.append('nickName', this.form.nickName)

      params.append('url', this.form.url)
      params.append('level', this.form.level)
      params.append('isLeaf', this.form.isLeaf)
      params.append('sort', this.form.sort)
      params.append('remark', this.form.remark)
      catalogEdit(params).then(res => {
        if (res.data.code === 100) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.dataList()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 删除
    delNode () {
        let curId= this.form.id
        let curType= this.form.type
        let nodeName= this.form.cnName

       if(curType=='manual'){
          this.$message.error('目录根节点无法删除')
          return false;
        }
        if(curId==0){
          this.$message.error('请选中需要删除的目录节点')
          return false;
        }
      this.$confirm('确定删除当前目录节点【'+ nodeName+ '】?', '删除目录节点', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        catalogDel(curId).then(res => {
          if(res.data.code==100){
            this.$message.success('删除成功')
            this.dataList()
            this.resetForm('form')
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }).catch((error)=>{
        this.$message.error('删除失败')
      })
    },
   
    // 重置
    resetForm (form) {
      if (this.$refs[form].resetFields() === undefined) {
        this.$refs[form].resetFields()
        this.form.id=0
        this.form.code=''
        this.form.cnName=''
        this.form.enName=''
        this.form.nickName=''
        this.form.url=''
        this.form.level=0
        this.form.isLeaf=''
        this.form.sort=0
        this.form.remark=''
      }
    }
  },
  mounted () {
    this.manualId =this.$route.params.id
    this.manualVersion =this.$route.params.manualVersion
    this.uploadUrl = cmsServerUrl+"sys/upload/procFile"
    this.dataList()
  }
}
</script>
<style scoped>
  .fromRight{
    border:1px solid #eee;
  }
  .fromRight>div{
    background: #fafafa;
    font-weight: bold;
    font-size: 14px;
    padding: 15px 0;
    text-align: center;
    border-bottom:1px solid #eee;
    box-sizing: border-box;
  }
  .fromRight .el-form-item{
    padding: 10px 15px 10px 0;
    border-bottom:1px solid #eee;
    margin-bottom:0px !important;
    box-sizing: border-box;
  }
</style>
<style>
  .fromRight .butArea .el-form-item__content{
    width: 100% !important;
    display: inline-block !important;
    text-align: center !important;
    margin: 0 auto !important
  }
  .fromRight .el-select{
    width: 100%
  }

  .upload-area{
    text-align: left;
    width: 400px;
    height: 180px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #dedede;
  }
</style>
