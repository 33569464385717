<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="手册类型" prop="queryManualType">
          <el-select v-model="formInline.queryManualType">
            <el-option v-for="(item,index) in manualTypeList" :key="index" :value="item.code" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布名称" prop="queryReleaseName">
          <el-input v-model="formInline.queryReleaseName" placeholder="请输入规则名称"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="addData()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
         <el-table-column label="ID" prop="id" width="50"></el-table-column>
        <el-table-column label="手册类型" prop="manualTypeName" width="150"></el-table-column>
        <el-table-column label="发布名称" prop="releaseName" width="100"></el-table-column>
        <el-table-column label="解析规则" prop="parseName" width="220"></el-table-column>
        <el-table-column label="前提条件" prop="parsePrecondition" width="100"></el-table-column>
        <el-table-column label="发布状态" prop="status" width="130">
          <template slot-scope="{row}">
            <span v-if="row.status === 1">未开始</span>
            <span v-if="row.status === 2" style="color:'#009933'">发布中</span>
            <span v-if="row.status === 3">发布完成</span>
            <span v-if="row.status === 4">发布失败</span>
          </template>
        </el-table-column>
        <el-table-column label="发布结果" prop="result" width="200"></el-table-column>
        <el-table-column label="更新人" prop="updatedUserName" width="150"></el-table-column>
        <el-table-column label="更新时间" prop="updatedTime" :formatter="dateFormat" width="150"></el-table-column>
        <el-table-column label="操作" fixed="right" width="230">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="headelEdit(row)">编辑</el-button>
            <el-button type="primary" size="mini" style="background:#FF5722" @click="headelDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit'" :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="手册类型" prop="manualType" :label-width="formLabelWidth">
            <el-select v-model="temp.manualType" @change="onChangeManualType">
              <el-option v-for="(item,index) in manualTypeList" :key="index" :value="item.code" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发布名称" prop="releaseName" :label-width="formLabelWidth">
            <el-input v-model="temp.releaseName" placeholder="请输入发布名称"></el-input>
          </el-form-item>
          <el-form-item label="解析规则" prop="parseCode" :label-width="formLabelWidth">
            <el-select v-model="temp.parseCode">
              <el-option v-for="(item, index) in manualParseList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
       
          <el-form-item label="前提条件" prop="preconditiongArr" :label-width="formLabelWidth">
             <el-checkbox-group v-model="temp.preconditiongArr">
                <el-checkbox v-for="(item, index) in preconditionList" :key="index"  :label="item.id" >{{item.releaseName}}</el-checkbox>
             </el-checkbox-group>
          </el-form-item>
         
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('temp') : editClick('temp')">立即提交</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { configData, configManualType, configSearch, configParse, configCondition, configAdd,
configEdit, configDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        queryReleaseName: '',
        queryManualType: ''
      },
      temp: {
        id: '',
        projectId: '',
        releaseName: '',
        manualType: '',
        manualTypeName:'',
        parseCode: '',
        parsePrecondition: '',
        preconditiongArr: [],
        status: '',
        issueResult: '',
        remark: ''
      },
      issuerList: [],
      manualTypeList: [],
      manualParseList: [],
      preconditionList: [],
      projectId: '',
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        edit: '编辑配置',
        add: '新增配置'
      },
      resultList: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      formLabelWidth: '100px',
      rules: {
        manualType: [{ required: true, message: '请选择手册类型', tigger: 'blur' }],
        releaseName: [{ required: true, message: '请输入发布名称', trigger: 'blur' }],
        parseCode: [{ required: true, message: '请选择解析规则', trigger: 'blur' }]
      }
    }
  },
  watch: {
    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.projectId = this.$route.params.projectId
        this.getManualTypeList()
        this.dataList()
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        projectId:this.projectId,
        page: this.currentPage,
        limit: this.pageSize
      }
      configData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 获取手册分类
    getManualTypeList () {
      configManualType().then(res => {
        if (res !== null && res.data.code === 100) {
          this.manualTypeList = res.data.data
        }
      })
    },
    // 搜索
    onSubmit () {
      var params = {
        page: this.currentPage,
        limit: this.pageSize,
        queryManualType: this.formInline.queryManualType,
        queryReleaseName: this.formInline.queryReleaseName
      }
      configSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 重置
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      if (this.dialogStatus === 'add' || this.dialogStatus === 'edit') {
        this.$refs.temp.resetFields()
      }
    },
   
    onChangeManualType(value){
      this.manualParseList=[]
      this.preconditionList=[]
      if(value !=null && value !=""){
        this.getManualParseList(value)
        this.getPreconditionList(value)
      }
    },
    // 手册解析规则列表
    getManualParseList (manualType) {
      var params = {
        userFlag: 1,
        manualType: manualType
      }
      configParse(params).then(res => {
        if (res.data.code === 100) {
          this.manualParseList = res.data.data
        }
      })
    },
    //获取前提条件列表
    getPreconditionList(manualType){
      var params = {
        projectId: this.projectId
      }
      configCondition(params).then(res => {
        if (res.data.code === 100) {
          var configList = res.data.data
          this.preconditionList=[]
          for(let i=0;i<configList.length;i++){
             let curManualType = configList[i].manualType
             if(curManualType!=manualType && curManualType!=null &&curManualType!=''){
               this.preconditionList.push(configList[i])
             }
          }
        }
      })
    },
    resetTemp () {
      this.temp.releaseName = ''
      this.temp.manualType = ''
      this.temp.parseCode = ''
      this.temp.parsePrecondition = []
    },
    // 新增
    addData () {
      this.resetTemp()
      this.manualParseList=[]
      this.preconditionList=[]
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('projectId', this.projectId)
          params.append('manualType', this.temp.manualType)
          params.append('releaseName', this.temp.releaseName)
          let curPrecondition=''
          if(this.temp.preconditiongArr!=null && this.temp.preconditiongArr.length>0){
            curPrecondition= this.temp.preconditiongArr.join();
          }
          params.append('parseCode', this.temp.parseCode)
          params.append('parsePrecondition', curPrecondition)
          configAdd(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              this.$message.error('提交失败,请重试')
            }
          })
        } else {
          this.$message.error('完善信息')
        }
      })
    },
    // 编辑
    headelEdit (row) {
      this.resetTemp()
      this.temp.id=row.id
      this.temp.releaseName = row.releaseName
      this.temp.manualType = row.manualType
      this.temp.parseCode = row.parseCode
      this.temp.parsePrecondition = row.parsePrecondition

       this.getManualParseList(row.manualType)
       this.getPreconditionList(row.manualType)

      if(row.parsePrecondition !=null && row.parsePrecondition !=''){
        let curArr = row.parsePrecondition.split(',')
        for(let i=0;i<curArr.length;i++){
          this.temp.preconditiongArr.push(parseInt(curArr[i]))
        }
      }
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
    },
    editClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('projectId', this.projectId)
          params.append('id', this.temp.id)
          params.append('manualType', this.temp.manualType)
          params.append('releaseName', this.temp.releaseName)
          let curPrecondition=''
          if(this.temp.preconditiongArr!=null && this.temp.preconditiongArr.length>0){
              curPrecondition= this.temp.preconditiongArr.join();
          }
          params.append('parseCode', this.temp.parseCode)
          params.append('parsePrecondition', curPrecondition)
          configEdit(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              this.$message.error('提交失败,请重试')
            }
          })
        } else {
          this.$message.error('完善信息')
        }
      })
    },
       // 删除
    headelDelete (row) {
      this.$confirm('确定删除该行?', '删除发布配置', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        configDel(row.id).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.dataList()
          }
        })
      }).catch((error)=>{
        this.$message.error('删除失败')
      })
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.projectId = this.$route.params.projectId
    this.getManualTypeList()
    this.dataList()
  }
}
</script>
