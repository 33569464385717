import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/login'
import Home from '../views/Home'
import index from '../views/dashboard/index'
import dashboard from '../views/dashboard/workbench'
import allProject from '../views/dashboard/project.vue'
import version from '../views/cmsmgt/manual/version.vue'
import catalog from '../views/cmsmgt/manual/catalog.vue'
import content from '../views/cmsmgt/manual/content.vue'
import manage from '../views/cmsmgt/manual/manage.vue'
import outline from '../views/cmsmgt/syllabus/list.vue'
import audit from '../views/cmsmgt/audit/audit.vue'
import auditDetail from '../views/cmsmgt/audit/detail.vue'
import imgDetail from '../views/cmsmgt/image/detail.vue'
import release from '../views/releasemgt/issue/release.vue'
import config from '../views/releasemgt/issue/config.vue'
import store from '../store/index'
import { Message } from "element-ui";  // 引入


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: dashboard
      },
      {
        path: '/allProject',
        name: 'allProject',
        component: allProject
      },
      {
        path: '/audit/:id/:version',
        name: 'audit',
        component: audit
      },
      {
        path: '/auditDetail/:id/:version',
        name: 'auditDetail',
        component: auditDetail
      },
      {
        path: '/version/:id',
        name: 'version',
        component: version
      },
      {
        path: '/catalog/:id/:manualVersion',
        name: 'catalog',
        component: catalog
      },
      {
        path: '/content/:id/:manualVersion',
        name: 'content',
        component: content
      },
      {
        path:'/outline',
        name:'outline',
        component: outline
      },
      {
        path: '/manage/:id',
        name: 'manage',
        component: manage
      },
      {
        path: '/imgDetail/:manualId/:manualVersion',
        name: 'imgDetail',
        component: imgDetail
      },
      {
        path: '/release/:projectId',
        name: 'release',
        component: release
      },
      {
        path: '/config/:projectId',
        name: 'config',
        component: config
      },
      {
        path: '/sysmgt/dict/list',
        component: () => import('@/views/sysmgt/dict/list'),
        name: 'sysmgt/dict/list'
      },
      {
        path: '/sysmgt/user/list',
        component: () => import('@/views/sysmgt/user/list'),
        name: 'sysmgt/user/list'
      },
      {
        path: '/sysmgt/role/list',
        component: () => import('@/views/sysmgt/role/list'),
        name: 'sysmgt/role/list'
      },
      {
        path: '/sysmgt/menu/list',
        component: () => import('@/views/sysmgt/menu/list'),
        name: 'sysmgt/menu/list'
      },
      {
        path: '/sysmgt/oauth/list',
        component: () => import('@/views/sysmgt/oauth/list'),
        name: 'sysmgt/oauth/list'
      },
      {
        path: '/sysmgt/tenant/list',
        component: () => import('@/views/sysmgt/tenant/list'),
        name: 'sysmgt/tenant/list'
      },
      {
        path: '/sysmgt/tenant/account',
        component: () => import('@/views/sysmgt/tenant/account'),
        name: 'sysmgt/tenant/account'
      },
      {
        path: '/sysmgt/org/list',
        component: () => import('@/views/sysmgt/org/list'),
        name: 'sysmgt/org/list'
      },
      {
        path: '/sysmgt/region/list',
        component: () => import('@/views/sysmgt/region/list'),
        name: 'sysmgt/region/list'
      },
      {
        path: '/carmgt/train/list',
        component: () => import('@/views/carmgt/train/list'),
        name: 'carmgt/train/list'
      },
      {
        path: '/carmgt/model/list',
        component: () => import('@/views/carmgt/model/list'),
        name: 'carmgt/model/list'
      },
      {
        path: '/fdmmgt/manual/list',
        component: () => import('@/views/fdmmgt/manual/list'),
        name: 'fdmmgt/manual/list'
      },
      {
        path: '/cmsmgt/project/list',
        component: () => import('@/views/cmsmgt/project/list'),
        name: 'cmsmgt/project/list'
      },
      {
        path: '/cmsmgt/manual/list',
        component: () => import('@/views/cmsmgt/manual/list'),
        name: 'cmsmgt/manual/list'
      },
      {
        path: '/cmsmgt/audit/list',
        component: () => import('@/views/cmsmgt/audit/list'),
        name: 'cmsmgt/audit/list'
      },
      {
        path: '/cmsmgt/image/list',
        component: () => import('@/views/cmsmgt/image/list'),
        name: 'cmsmgt/image/list'
      },
      {
        path: '/releasemgt/issue/list',
        component: () => import('@/views/releasemgt/issue/list'),
        name: 'releasemgt/issue/list'
      },
      {
        path: '/releasemgt/style/list',
        component: () => import('@/views/releasemgt/style/list'),
        name: 'releasemgt/style/list'
      },
      {
        path: '/releasemgt/parse/list',
        component: () => import('@/views/releasemgt/parse/list'),
        name: 'releasemgt/parse/list'
      }
    ]
  }
]

if (sessionStorage.getItem('token')) {
  store.commit('set_token', sessionStorage.getItem('token'))
}
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path ==='/'){
    next()
  } else {
    if(store.state.token){
      next()
    }else{
      Message.error("请登录");
      next({name:'login'})
    }
  }

})
export default router
