import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './plugins/element.js'
import qs from 'qs'
import axios from 'axios'
import './assets/css/common.css'
import './api/api'
import Router from 'vue-router'
import './plugins/codemirror'
import i18n from './i18n/i18n'
import { Message } from "element-ui";  // 引入
Vue.use(Router, axios)
// 解决with new navigation
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace

// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
Vue.prototype.$qs = qs
Vue.config.productionTip = false

Vue.prototype.validForbid = function (value) {
  //value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\·~！@#￥%……&*（）——\-+={}|\\《》？：“”【】\[\]、；‘’，。、]/g, '').replace(/\s/g, "")
  //.replace(/\s/g, "") 去掉空格 
  //.replace(/^\s+|\s+$/g,'') 禁止前后输入空格，不禁止中间输入空格
  value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\·~！@#￥%……&*（）——\-+={}|\\《》？：“”【】\[\]、；‘’，。、]/g, '').replace(/^\s+|\s+$/g,'')
  return value
}
Vue.prototype.hasPerm= function(value){
  let perms = store.state.perms
  if(perms && perms instanceof Array && perms.length>0){
    if(perms.indexOf(value) > -1)
    return true
  }else{
    return false
  }
}


Vue.prototype.hasRole= function(value){
  let roles = store.state.roles
  if(roles && roles instanceof Array && roles.length>0){
    if(roles.indexOf(value) > -1)
    return true
  }else{
    return false
  }
}


/** 权限指令,对按钮权限的控制 **/
const hasPermission = Vue.directive("hasPermission", {
  inserted: function(el, binding) {
    if (!Vue.prototype.$_hasPermission(binding.value)) {
      el.parentNode.removeChild(el);
    }
  },
});

Vue.prototype.$_hasPermission= function(value){
  let perms = store.state.perms
  if(perms && perms instanceof Array && perms.length>0){
    if(perms.indexOf(value) > -1)
    return true
  }else{
    return false
  }
}
export { hasPermission };

Vue.prototype.parserNumber=function(value,min,max){
      if(value!=null&&value.length>0){
        if(!isNaN(value)){
          console.log(value)
          let v = parseInt(value)
            if(v > max){
              value= max
            }else if(v < min){
                value= min
            }else{
              value=  v
            }
        }else{
          value=  1
        }
      }else{
        value=  1
      }
      

      return value
}


//axios.defaults.headers.common['Authorization'] = store.state.token
// axios.defaults.withCredentials = false

// 添加请求拦截器
axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  //判断是否存在token，如果存在将每个页面header都添加token
  if(store.state.token){
    config.headers.common['Authorization']=store.state.token
  }else{
    router.push({name: 'login'}); 
  }
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});
   
// http response 拦截器
axios.interceptors.response.use(response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
        Message.error("请登录");
        this.$store.commit('del_token');
        router.push({name: 'login'});
      }
    }
    return Promise.reject(error)
  }
);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
