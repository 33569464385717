<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="发布名称" prop="queryReleaseName">
          <el-input v-model="formInline.queryReleaseName" placeholder="请输入发布名称"></el-input>
        </el-form-item>
        <el-form-item label="发布状态" prop="queryStatus">
          <el-select v-model="formInline.queryStatus">
            <el-option value="1" label="未开始"></el-option>
            <el-option value="2" label="发布中"></el-option>
            <el-option value="3" label="发布完成"></el-option>
            <el-option value="4" label="发布失败"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布类型" prop="queryReleaseType">
          <el-select v-model="formInline.queryReleaseType">
            <el-option value="1" label="预览"></el-option>
            <el-option value="2" label="发布"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.begintime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.endtime"></el-date-picker>
        </el-form-item>
        <el-form-item label="发布人" prop="queryIssuer">
          <el-select v-model="formInline.queryIssuer">
            <el-option v-for="(item, index) in issuerList" :key="index" :value="item.id" :label="item.realName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="addData()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="发布名称" prop="releaseName" width="190"></el-table-column>
        <el-table-column label="版本号" prop="versionNumber" width="190"></el-table-column>
        <el-table-column label="输出类型" prop="outputType" width="100"></el-table-column>
        <el-table-column label="发布类型" prop="releaseType" width="100">
          <template slot-scope="{row}">
            <span v-if="row.releaseType === 1">预览</span>
            <span v-if="row.releaseType === 2">发布</span>
          </template>
        </el-table-column>
        <el-table-column label="有效状态" prop="useFlag" width="120">
          <template slot-scope="{row}">
            <span v-if="row.useFlag === 1">启用</span>
            <span v-if="row.useFlag !== 1" style="color:red">禁用</span>
          </template>
        </el-table-column>

        <el-table-column label="发布状态" prop="status" width="130">
          <template slot-scope="{row}">
            <span v-if="row.status === 1">未开始</span>
            <span v-if="row.status === 2" style="color:'#009933'">发布中</span>
            <span v-if="row.status === 3">发布完成</span>
            <span v-if="row.status === 4">发布失败</span>
          </template>
        </el-table-column>
        <el-table-column label="发布结果" prop="issueResult" width="200"></el-table-column>
        <el-table-column label="发布人" prop="updatedUserName" width="150"></el-table-column>
        <el-table-column label="发布时间" prop="updatedTime" :formatter="dateFormat" width="150"></el-table-column>
        <el-table-column label="操作" fixed="right" width="230">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="headleDetail(row)">查看</el-button>
            <el-button type="primary" v-if="row.useFlag === 1" size="mini" style="background:red" @click="headleEnable(row,0)">禁用</el-button>
            <el-button type="primary" v-if="row.useFlag !== 1" size="mini" @click="headleEnable(row,1)">启用</el-button>
            <!-- <el-button type="primary" size="mini" @click="headleEdit(row)">编辑</el-button> -->
            <el-button type="primary" size="mini" style="background:#FF5722" @click="headelDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit'" :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="发布名称" prop="configId"  :label-width="formLabelWidth">
            <el-select v-model="temp.configId">
              <el-option v-for="(item, index) in releaseConfigList" :key="index" :label="item.releaseName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="输出类型" prop="outputType" :label-width="formLabelWidth">
            <el-select v-model="temp.outputType">
              <el-option value="html" label="html"></el-option>
              <el-option value="pdf" label="pdf"></el-option>
            </el-select>
          </el-form-item>
         <el-form-item label="发布类型" prop="releaseType" :label-width="formLabelWidth">
             <el-select v-model="temp.releaseType">
              <el-option v-for="(item, index) in releaseTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效状态" prop="useFlag" :label-width="formLabelWidth">
            <el-select v-model="temp.useFlag">
              <el-option v-for="(item, index) in useFlagList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model="temp.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('temp') : editClick('temp')">立即提交</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dlgDetialFormVisible" >
        <el-form v-if="dialogStatus === 'detail'" :model="temp" ref="temp" label-position="center" >
          <el-form-item label="发布名称" prop="configId"  :label-width="formLabelWidth">
            <el-select v-model="temp.configId">
              <el-option v-for="(item, index) in releaseConfigList" :key="index" :label="item.releaseName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="输出类型" prop="outputType" :label-width="formLabelWidth">
            <el-select v-model="temp.outputType">
              <el-option value="html" label="html"></el-option>
              <el-option value="pdf" label="pdf"></el-option>
            </el-select>
          </el-form-item>
         <el-form-item label="发布类型" prop="releaseType" :label-width="formLabelWidth">
             <el-select v-model="temp.releaseType">
              <el-option v-for="(item, index) in releaseTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效状态" prop="useFlag" :label-width="formLabelWidth">
            <el-select v-model="temp.useFlag">
              <el-option v-for="(item, index) in useFlagList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model="temp.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { releaseData, releaseSearch, releaseUser, releaseConfig, releaseAdd, releaseEnable, releaseDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        queryReleaseName: '',
        queryStatus: '',
        queryReleaseType:'',
        begintime: '',
        endtime: '',
        queryIssuer: ''
      },
      temp: {
        id: '',
        configId: '',
        releaseName: '',
        versionNumber: '',
        outputType: '',
        releaseType: '',
        useFlag: '',
        remark: ''
      },
      releaseTypeList: [
        { name: '预览', code: 1 },
        { name: '发布', code: 2 }
      ],
      useFlagList: [
        { name: '启用', code: 1 },
        { name: '禁用', code: 0 }
      ],
      issuerList: [],
      releaseConfigList: [],
      projectId: '',
      dialogFormVisible: false,
      dlgDetialFormVisible:false,
      dialogStatus: '',
      textMap: {
        edit: '编辑发布',
        detail: '发布明细',
        add: '新增发布'
      },
      resultList: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      formLabelWidth: '100px',
      rules: {
        configId: [{ required: true, message: '请选择发布名称',  trigger: 'blur'  }],
        outputType: [{ required: true, message: '请选择输出类型', trigger: 'blur' }],
        releaseType: [{ required: true, message: '请选择发布类型', trigger: 'blur' }],
        useFlag: [{ required: true, message: '请选择有效状态', trigger: 'blur' }]
      }
    }
  },
   watch: {
    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.projectId = this.$route.params.projectId
        this.dataList()
        this.getReleaseConfigList()
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        projectId:this.projectId
      }
      releaseData(params).then(res =>{
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 搜索
    onSubmit () {
      var params = {
        page: this.currentPage,
        limit: this.pageSize,
        queryReleaseName: this.formInline.queryReleaseName,
        queryStatus: this.formInline.queryStatus,
        begintime: this.formInline.begintime,
        endtime: this.formInline.endtime,
        queryIssuer: this.formInline.queryIssuer
      }
      releaseSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 重置
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      if (this.dialogStatus === 'add' || this.dialogStatus === 'edit') {
        this.$refs.temp.resetFields()
      }
    },
    // 获取用户列表
    getUserList () {
      releaseUser().then(res => {
        if (res.data.code === 100) {
          this.issuerList = res.data.data
        }
      })
    },
 
    //获取发布配置列表
    getReleaseConfigList(){
      var params = {
        projectId: this.projectId
      }
      releaseConfig(params).then(res => {
        if (res.data.code === 100) {
          this.releaseConfigList= res.data.data
        }
      })
    },
    resetTemp () {
      this.temp = {
        id: '',
        configId:'',
        releaseName: '',
        versionNumber: '',
        outputType: '',
        releaseType: '',
        useFlag: '',
        remark: ''
      }
    },
    // 新增
    addData () {
      this.resetTemp()
      this.temp.outputType="html"
      this.temp.releaseType=2
      this.temp.useFlag=1

      this.dialogStatus = 'add'
      this.dialogFormVisible = true
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('projectId', this.projectId)
          params.append('configId', this.temp.configId)
          params.append('outputType', this.temp.outputType)
          params.append('releaseType', this.temp.releaseType)
          params.append('useFlag', this.temp.useFlag)
          params.append('remark', this.temp.remark)
          releaseAdd(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              this.$message.error('提交失败,请重试')
            }
          })
        } else {
          this.$message.error('请完善表单信息')
        }
      })
    }, 
    // 查看
    headleDetail (row) {
      this.temp = Object.assign({}, row) 
      this.dialogStatus = 'detail'
      this.dlgDetialFormVisible = true
    },
    // 编辑
    // headleEdit (row) {
    //   this.resetTemp()
    //   this.temp = Object.assign({}, row) 
    //   this.dialogStatus = 'edit'
    //   this.dialogFormVisible = true
    // },
    // editClick (temp) {
    //   this.$refs[temp].validate((valid) => {
    //     if (valid) {
    //       var params = new URLSearchParams()
    //       params.append('id', this.temp.id)
    //       params.append('projectId', this.projectId)
    //       params.append('configId', this.temp.configId)
    //       params.append('outputType', this.temp.outputType)
    //       params.append('releaseType', this.temp.releaseType)
    //       params.append('useFlag', this.temp.useFlag)
    //       params.append('remark', this.temp.remark)
    //       axios({
    //         method: 'post',
    //         url: cmsServerUrl + 'cms/manual/release/edit',
    //         data: params,
    //         headers: { Authorization: sessionStorage.token }
    //       }).then(res => {
    //         if (res.data.code === 100) {
    //           this.$message({
    //             message: res.data.msg,
    //             type: 'success'
    //           })
    //           this.dataList()
    //           this.dialogFormVisible = false
    //         } else {
    //           this.$message.error(res.data.msg)
    //         }
    //       }).catch(err => {
    //         if (err !== null && err !== '' && err.responseText !== null) {
    //           this.$message.error('提交失败,请重试')
    //         }
    //       })
    //     } else {
    //       this.$message.error('请完善表单信息')
    //     }
    //   })
    // },

    headleEnable(row,flag){
      var params = new URLSearchParams()
      params.append('id', row.id)
      params.append('useFlag', flag)
      releaseEnable(params).then(res => {
        let msg= res.data.msg
        msg='操作成功'
        if (res.data.code === 100) {
          this.$message({
            message: msg,
            type: 'success'
          })
          this.dataList()
        } else {
          msg='操作失败'
          this.$message.error(msg)
        }
      }).catch(err => {
        if (err !== null && err !== '' && err.responseText !== null) {
          this.$message.error('操作失败,请重试')
        }
      })
    },

    // 删除
    headelDelete (row) {
      this.$confirm('确定删除该行?', '删除发布记录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        releaseDel(row.id).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.dataList()
          }
        })
      }).catch((error) => {
        this.$message.error('删除失败')
      })
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.projectId = this.$route.params.projectId
    this.dataList()
    this.getReleaseConfigList()
    this.getUserList()
  }
}
</script>
