<template>
  <div class="detailPage" v-cloak>
    <el-container>
      <!-- 左侧目录 -->
      <el-aside width="collapse">
        <div class="logo"  width="collapse">
          <img class="openLogo" @click="logoClick()" src="../../assets/image/logo.png" alt="">
          <img class="foldLogo" @click="logoClick()" src="../../assets/image/baixin.png">
        </div>     
        <el-menu
          :default-active="activeUrl" 
          :collapse="isCollapse"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#00152a"
          :collapse-transition="true"
          router
        >
          <el-menu-item index="/dashboard">
            <img src="../../assets/image/home.png" alt="">
            <span slot="title">{{$t('catalogue.oneTitle')}}</span>
          </el-menu-item>
          <el-submenu :index="num+'2'" v-for="(item, num) of newList" :key='num'>
            <template slot="title">
              <img v-if="item.icon !== ''" :src="imgSrc + item.icon" alt="">
              <img v-if="item.icon == ''" src="../../assets/image/common.png" alt="">
              <span>{{item.name}}</span>
            </template>
            <el-menu-item-group v-for="(itm, idx) of item.children" :key='idx'>
              <template slot="title"></template>
              <div v-if="itm.url.indexOf('.html') != '-1'">
                <el-menu-item class="groupName" :index="itm.url.split('.html')[0].substring(2, itm.url.split('.html')[0].length)" 
                  @click="tabsNav($event,itm.name, itm.url)">
                  <img v-if="itm.icon !== null && itm.icon !== 'null' && itm.icon !== ''" :src="imgSrc + itm.icon" alt="">
                  <img v-if="itm.icon == null" src="../../assets/image/template.png" alt="">
                  <img v-if="itm.icon == 'null'" src="../../assets/image/template.png" alt="">
                  <img v-if="itm.icon == ''" src="../../assets/image/template.png" alt="">
                  {{itm.name}}
                </el-menu-item>
              </div>
              <div v-if="itm.url.indexOf('.html') == '-1'">
                <el-menu-item class="groupName" :index="itm.url" 
                  @click="tabsNav($event,itm.name, itm.url)">
                  <img v-if="itm.icon !== null && itm.icon !== 'null' && itm.icon !== ''" :src="imgSrc + itm.icon" alt="">
                  <img v-if="itm.icon == null" src="../../assets/image/template.png" alt="">
                  <img v-if="itm.icon == 'null'" src="../../assets/image/template.png" alt="">
                  <img v-if="itm.icon == ''" src="../../assets/image/template.png" alt="">
                  {{itm.name}}
                </el-menu-item>
              </div> 
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <!-- 顶部导航 -->
        <el-header>
          <div class="rightNav">
            <div class="leftArea">
              <div style="padding:0 15px;" @click="toggleClick()">
                <svg :class="{'is-active':isIconActive}" 
                  class="hamburger" viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                >
                  <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
                </svg>
              </div>
            </div>
            <div class="rightArea">
              <div>
                <img src="../../assets/image/search.png" alt="">
              </div>
              <div>
                <img src="../../assets/image/help.png" alt="">
              </div>
              <div>
                <img src="../../assets/image/notice.png" alt="">
              </div>
              <div class="dropdrow" v-cloak>
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <img v-if="userInfo.headimgurl == null" src="http://t.cn/RCzsdCq" alt="">
                    <img v-if="userInfo.headimgurl !== null" :src="userInfo.headimgurl" alt="">
                    {{userInfo.username}}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="basicInfo()">{{$t('nav.data')}}</el-dropdown-item>
                    <el-dropdown-item @click.native="password()">{{$t('nav.change')}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div @click="signOut()">
                {{$t('nav.signOut')}}
              </div>
            </div>
          </div>
        </el-header>
        <el-main >
          <div class="tabsTag">
            <router-link
              v-for="(tag, idx) in tabsList"
              ref="tag"
              :key="tag.path"
              :class="isActive(tag) ? 'active ': ''"
              :to="{ path: tag.path }"
              tag="span"
              class="tags-view-item"
            >
              {{tag.name}}
              <span v-if="idx > 0" class="el-icon-close" @click.stop="closeTag(tag, idx)"></span>
            </router-link>
          </div>
          <div class="centerArea">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </el-main>
        <el-footer>{{$t('foot.name')}}</el-footer>
      </el-container>
      <div class="bgStyle">
        <el-color-picker v-model="color" @change="changeThemeColor"></el-color-picker>
      </div>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form v-if="dialogStatus === 'info'">
          <el-form-item label="账号名" prop="username" :label-width="formLabelWidth">
            <el-input type="text" v-model="infoData.username" disabled=""></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="realName" :label-width="formLabelWidth">
            <el-input type="text" v-model="infoData.realName"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="userType" :label-width="formLabelWidth">
            <el-radio-group v-model="infoData.userType">
              <el-radio :label="1">前台</el-radio>
              <el-radio :label="2">后台</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="性别" prop="sex" :label-width="formLabelWidth">
            <el-radio-group v-model="infoData.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
            <el-input type="email" v-model="infoData.email"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="telephone" :label-width="formLabelWidth">
            <el-input type="tel" v-model="infoData.telephone"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address" :label-width="formLabelWidth">
            <el-input type="text" v-model="infoData.address"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="infoSubmit('infoData')">
              提交修改
            </el-button>
            <!-- <el-button @click="resetinfo('infoData')">
              重置
            </el-button> -->
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'password'" :rules="formPwd" ref="modify" :model="modify">
          <el-form-item label="旧密码" prop="oldPassword" :label-width="formLabelWidth">
            <el-input type="password" v-model="modify.oldPassword" placeholder="请输入旧密码"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword" :label-width="formLabelWidth">
            <el-input type="password" v-model="modify.newPassword" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确定密码" prop="confirmPassword" :label-width="formLabelWidth">
            <el-input type="password" v-model="modify.confirmPassword" placeholder="请输入确定密码"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="cipherSubmit('modify')">
              立即提交
            </el-button>
            <el-button @click="resetPwd('modify')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-container>
  </div>
</template>
<script>
import { sysServerUrl, tagsList, catalogueList, obj } from '@/assets/js/common.js'
import { getUserInfo, catalog } from '@/api/api.js'
import axios from 'axios'
import $ from 'jquery'
export default {
  name: 'detailPage',
  data () {
    return {
      imgSrc: sysServerUrl + "sys/upload/display?filePath=",
      activeUrl: '/dashboard',
      isCollapse:false,
      isIconActive:false,
      newList: [],
      userInfo: [],
      tabsList: [],
      color: '#1890ff',
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        info: '基本资料',
        password: '修改密码'
      },
      // 基本资料
      infoData: {
        username:'',
        realName: '',
        userType: '',
        sex: '',
        email: '',
        telephone: '',
        address:'',
      },
      // 修改密码
      modify: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formPwd: {
        oldPassword: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }],
        newPassword: [{ required: true, message: '新密码不能为空', trigger: 'blur' }],
        confirmPassword: [{ required: true, message: '确定密码不能为空', trigger: 'blur' }]
      },
      formLabelWidth: '100px',
    }
  },
  methods: {
    handleCloseCreate () {
      this.dialogFormVisible = false
      if (this.dialogStatus === 'add') {
        this.$refs.temp.resetFields()
      } else if (this.dialogStatus === 'password') {
       this.$refs.modify.resetFields()
     }
    },
    // 基本资料
    basicInfo () {
      this.infoData = Object.assign({}, this.userInfo)
      this.dialogFormVisible = true
      this.dialogStatus = 'info'
    },
    infoSubmit (infoData) {
      this.$refs[infoData].validate((valid) => {
        if(valid) {
          console.log('submit!')
        }else{
          this.$message.error('')
        }
      })
    },
    // 修改密码
    password (){
      this.dialogFormVisible = true
      this.dialogStatus = 'password'
    },
    cipherSubmit (modify) {
      this.$refs[modify].validate((valid) => {
        if (valid) {
          console.log('submit!')
        } else {
          this.$message.error('请填写完整信息')
        }
      })
    },
    resetPwd (modify) {
      if (this.$refs[modify].resetFields() !== undefined) {
        this.$refs[modify].resetFields()
      }
    },
    // 左侧目录显示隐藏
    toggleClick(){
      this.isCollapse = !this.isCollapse
      this.isIconActive = !this.isIconActive
      if(this.isCollapse == true){
        setTimeout(function(){
          $('.openLogo').hide()
          $('.foldLogo').show()
        },0)
      }else{
        setTimeout(function(){
          $('.openLogo').show()
          $('.foldLogo').hide()
        },300)
      }
    },
    collapse () {
      this.collapsed = !this.collapsed
    },
    // 字体颜色
    changeThemeColor (val) {
      this.color = val
      document.getElementById('app').style.setProperty('--themeColor', val)
    },
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    //导航 tab
    isActive (router) {
      // console.log(decodeURIComponent(this.$router.history.current.fullPath))
      // return router.path === this.$route.path
      let  clickedPath = decodeURIComponent(this.$router.history.current.fullPath)
      return  router.path === clickedPath
      let tabPath=tabPath=router.path
      let index =router.path.indexOf('?')
      if(index!=-1){
        tabPath=router.path.substring(0,index)
      }
      return tabPath === this.$router.history.current.path
    },
    // 增加
    tabsNav (e, name, url) {
      if(url.indexOf("html") !== -1){
        var tabUrl = url.split('.html')[0].substring(2, url.split('.html')[0].length)
      }else{
        var tabUrl = url
      }
      this.activeUrl = tabUrl
      this.tabsList = tagsList
      obj.tabsClick(name, tabUrl)
    },
    // 删除
    closeTag (tag, idx) {
      var _this = this
      let tabs = _this.tabsList
      let activeName = this.$route.path
      if(activeName === tag.path){
        tabs.forEach((tab,index) => {
          if(tab.path === tag.path){
            let nextTab = tabs[index + 1] || tabs[index - 1]
            if(nextTab){
              activeName = nextTab.path;
              this.tabClick(nextTab)
            }
          }
        })
      }
      _this.tabsList.splice(idx, 1)
    },
    tabClick(thisTab){
      let list = this.tabsList
      var val = list.filter(item => thisTab.name == item.name)
      this.$router.push({
        path: val[0].path
      })
    },
    // tab滚动
    moveToCurrentTag(){
      setTimeout(function(){
        var allWidth = $('.tabsTag').width()
        var left = $('.active').offset().left
        var spanWidth = $('.active').outerWidth(true)
        if(allWidth > left){
          $('.tabsTag').scrollLeft('0')
        }else{
          var distance = left + spanWidth
          $('.tabsTag').scrollLeft(distance)
        }
      },10)
    },
    // 登录
    logoClick () {
      this.$router.push('/index')
    },
    // 退出登录
    signOut () {
      this.$store.commit('del_token');
      this.$router.push('/')
    },
    // 用户信息
    userData () {
      var _this = this
      setTimeout(function () {
        getUserInfo().then(res => {
          _this.userInfo = res.data.data
        })
      }, 0)
    },
    // 目录信息
    catalogueList () {
      var _this = this
      catalog().then(res =>{
        _this.newList = res.data.data
      })
    },
    newUrl () {
      if (this.activeUrl === '/dashboard' && this.$router.history.current.path !== '/dashboard') {
        this.$router.push('/index')
      }
    },
    // 高度
    hieghtArea () {
      var distance = $('.el-main').outerHeight(true) - $('.tabsTag').outerHeight(true)
      $('.centerArea').css('height',distance)
    },
    areaSize () {
      var _this = this
      _this.hieghtArea()
      window.addEventListener('resize', function () {
        _this.hieghtArea()
      })
    }
  },
  mounted () {
    var _this = this
    obj.tabsClick(_this.$t('catalogue.oneTitle'), '/dashboard')
    _this.tabsList = tagsList
    _this.newUrl()
    _this.userData()
    _this.catalogueList()
    _this.areaSize()
  },
  watch: {
    $route(){
      this.moveToCurrentTag()
    }
  }
}
</script>
<style>
  .detailPage{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  /* 左侧目录 */
  .el-aside {
    background-color: #00152a;
    color: rgb(191, 203, 217);
    height:100vh;
    text-align: center;
    overflow-x: hidden !important;
  }
  .el-menu--collapse {
    width: 52px !important
  }
  /* logo */
  .el-aside .logo{
    background: #00152a;
    border-bottom: 1px solid #00152a;
    text-align: center;
    height: 60px;
    line-height: 60px;
  }
  .el-aside .logo img{
    vertical-align: middle;
  }
  .foldLogo{
    display:none;
    width: 35px;
  }
  .el-menu>li img{
    width: 17px;
    margin-right:10px;
  }
  .el-menu>li li img{
    width: 16px;
    margin-right: 1px;
    vertical-align: middle;
    margin-top: -2px;
  }
  /* 顶部导航 */
  .el-header{
    display: flex;
    padding: 0 !important;
    background: #fff;
    justify-content: space-between;
    color: #333;
    line-height: 60px;
  }
  .el-header div{
    cursor: pointer;
    box-sizing: border-box;
  }
  /* 右侧导航 */
  .hamburger{
    display: inline-block;
    vertical-align:middle;
    width:20px;
    height:20px;
  }
  .hamburger.is-active{
    transform:rotate(180deg)
  }
  .el-header .rightNav{
    flex: 1;
    display:flex;
    border-bottom: 1px solid #f0f0f0;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
  }
  .el-header .rightNav .rightArea{
    display: flex;
  }
  .el-header .rightNav .rightArea>div{
    padding: 0 18px;
    font-size: 15px;
    color: #333;
    display:flex;
    align-items:center;
    border-bottom:3px solid transparent
  }
  .el-header .rightNav .rightArea>div:hover{
    border-bottom: 3px solid var(--themeColor);
  }
  .rightArea div>img{
    width:18px;
  }
  /* 下拉框 */
  .dropdrow{
    font-size: 15px;
    color: #333;
  }
  .dropdrow img{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .el-header .el-dropdown span{
    display: inline-block;
    height: 100%;
  }
  /* 导航目录 */
  .tabsTag {
    position: relative;
    z-index: 1px;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    font-size: 13px;
    height: 37px;
    padding-left: 10px;
    padding-right: 5px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 0 3px rgb(0 0 0 / 4%)
  }
  /* .el-scrollbar {
    white-space: nowrap;
    width: 100%;
  }
  .el-scrollbar__wrap {
    height: 49px !important;
  } */
  .tags-view-item.router-link-exact-active.router-link-active.active::before {
    content: "";
    background: rgb(255, 255, 255);
    display: inline-block;
    width:7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 2px; 
  }
  /* 滚动条 */
  .tabsTag::-webkit-scrollbar{
    height: 5px;
  }
  .tabsTag::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.1);
  }
  .tabsTag::-webkit-scrollbar-track{
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgba(53,51,51,0.1);
  }
  .tags-view-item {
    display: inline-block;
    padding: 0 8px;
    cursor: pointer;
    height: 26px;
    line-height: 26px;
    border: 1px solid #d8dce5;
    color: #495060;
    background: #fff;
    box-sizing: border-box;
    margin-top: 4px;
    margin-left: 5px;
  }
  .tabsTag .el-icon-close{
    font-size: 12px;
    margin-left: 2px;
    padding: 1px;
  }
  .tabsTag .el-icon-close:hover{
    background: #b4bccc;
    color:#fff;
    padding: 1px;
    border-radius: 50%;
  }
  .el-icon-close:before {
    /* transform: scale(0.7);
    display: inline-block;
    vertical-align: 0.52px; */
  }
  .active{
    color:#fff;
    background: #42b983
  }
  .el-main {
    overflow: hidden !important;
    /* background-color: #f0f0f0; */
    padding: 0 !important;
  }
  .centerArea{
    overflow-y: auto;
    overflow-x: hidden;
  }
  body > .el-container {
    margin-bottom: 40px;
  }
  /* 颜色选择 */
  .bgStyle{
    position: fixed;
    z-index: 999;
    right: 0;
    top: 35%;
  }
  .bgStyle .el-color-picker {
    height: 48px;
  }
  .bgStyle .el-color-picker__trigger {
    height: 48px;
    width: 48px;
    padding: 0;
    border:none;
    border-radius: 8px 0 0 8px;
    overflow: hidden;
  }
  .bgStyle  .el-color-picker__color{
    border: none
  }
  .bgStyle .el-icon-arrow-down:before {
    content: "";
    background-image: url("../../assets/image/margen.png");
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: 100%
  }
</style>
<style>
  /* body */
  .el-container{
    height: 100%;
  }
  .el-container.is-vertical{
    width: 100%;
    height: 100%;
  }
  /* 目录 */
  .el-menu{
    border: none !important;
    text-align: left;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
  }
  /* 点击后的目录列表 */
  .el-submenu.is-opened .el-submenu__title,.el-submenu.is-opened .el-submenu__title i{
    color: #fff !important;
  }
  .el-submenu .el-menu-item.is-active,.el-menu-item.is-active,.el-menu-item{
    color: #fff !important;
    background: var(--themeColor) !important;
  }
  /* 目录列表 */
  .el-submenu__title,.el-menu-item{
    color: rgb(191, 203, 217) !important;
    background: #011f39 !important;
    border-right: 1px solid #011f39 !important;
  }
  .el-submenu__title i{
    color:rgb(191, 203, 217) !important
  }
  .el-submenu div:hover{
    color:rgb(255, 255, 255) !important;
    background: #062845 !important
  }
  .el-submenu__title:hover,
  .el-submenu__title i:hover{
    color:rgb(255, 255, 255) !important;
  }
  .el-menu.el-menu--inline{
    background: #00152a !important
  }
  .el-menu-item-group__title{
    background: #00152a !important;
    color:rgb(191, 203, 217) !important;
    text-align: left;
    padding: 0px 0 0px 20px !important;
  }
  .el-submenu .el-menu-item{
    padding:0px 18px 0 40px !important;
    background: #00152a !important;
    color:rgb(191, 203, 217) !important;
    border-left: 3px solid transparent;
    box-sizing: border-box;
  }
  .el-submenu .el-menu-item:hover{
    color:rgb(255, 255, 255) !important;
    border-left: 3px solid var(--themeColor);
  }
  .el-color-dropdown__btns .el-button--text{
    display: none;
  }
  /* 导航下拉菜单 */
  .el-dropdown {
    color: #333 !important;
    font-size: 15px !important;
  }
  /* 底部导航 */
  .el-footer{
    box-sizing: border-box;
    height: 40px !important;
    line-height: 40px;
    text-align: center;
    color: #333;
    font-size: 14px;
    border-top: 1px solid #f0f0f0;
  }
</style>
