<template>
  <div class="layoutContainer" style="height:100%">
    <div class="tableDetail" style="height:100%">
      <el-row class="elRow" style="height:100%">
        <el-col :span="9">
          <div style="margin-bottom:20px;">
            <el-upload
              class="upload-demo inline-block"
              ref="elUpload"
              action="#"
              :show-file-list="false"
               multiple
              :limit="1"
              :before-upload="onBeforeUpload"
              :http-request="uploadFile"
            >
              <el-button size="min" icon="el-icon-upload" type="primary">批量导入数据包</el-button>
            </el-upload>
          </div>
          <el-tree ref="tree" :data="listdata"   node-key="id" :default-expanded-keys="treeExpandIdList" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="14" style="height:100%">
          <div style="margin-bottom:20px;" class="butArea" >
            <el-button type="primary"  :disabled="showViewBtn" >预览效果</el-button>
            <el-button type="primary" @click="updateTopic"  v-show="showEditArea" >保存当前版本</el-button>
            <el-button type="primary" @click="showAddVersionDlg"  v-show="showEditArea" >另存为新版本</el-button>
          </div>
          <textarea ref="textarea"></textarea>
        </el-col>
      </el-row>
      <el-dialog title="确认生成新版本"  :visible.sync="dialogFormVisible" :close-on-click-modal="false">
        <el-form  ref='dataForm' label-position="center">
          <el-form-item label="版本备注:" prop="versionRemark" :label-width="formLabelWidth">
            <el-input type="textarea"  rows="5" v-model="versionRemark" aria-required="true"></el-input>
          </el-form-item>
           <el-form-item :label-width="formLabelWidth" style=" text-align:right;margin-right:20px">
            <el-button @click="dialogFormVisible = false" >
              取消
            </el-button>
             <el-button type="primary" @click="addTopic" >
              确认
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { cmsServerUrl, obj } from '@/assets/js/common.js'
import { contentData, contentImport, contentDetail, contentAdd } from '@/api/api.js'
import $ from 'jquery'
// 引入全局实例
import _CodeMirror from 'codemirror'
// 核心样式
import 'codemirror/lib/codemirror.css'
// 引入主题后还需要在 options 中指定主题才会生效
import 'codemirror/theme/cobalt.css'
// 需要引入具体的语法高亮库才会有对应的语法高亮效果
// codemirror 官方其实支持通过 /addon/mode/loadmode.js 和 /mode/meta.js 来实现动态加载对应语法高亮库
// 但 vue 貌似没有无法在实例初始化后再动态加载对应 JS ，所以此处才把对应的 JS 提前引入
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/clike/clike.js'
import 'codemirror/mode/markdown/markdown.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/mode/r/r.js'
import 'codemirror/mode/shell/shell.js'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/mode/swift/swift.js'
import 'codemirror/mode/vue/vue.js'
import axios from 'axios'
// 尝试获取全局实例
const CodeMirror = window.CodeMirror || _CodeMirror
export default {
  name: 'layoutContainer',
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'cnName'
      },
      formLabelWidth: '100px',
      listdata: [],
      treeExpandIdList:[],
      manualId: 0,
      manualVersion: '',
      directoryId: 0,
      versionRemark: '',
      dialogFormVisible: false,
      showEditArea: false,
      showViewBtn: true,
      // 内部真实的内容
      code: '',
      // 默认的语法类型
      mode: 'javascript',
      // 编辑器实例
      coder: null,
      // 默认配置
      options: {
        // 缩进格式
        tabSize: 2,
        // 主题，对应主题库 JS 需要提前引入
        theme: 'cobalt',
        // 显示行号
        lineNumbers: true,
        line: true
      }
    }
  },
 watch: {
    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.path !== from.path) {
        this.manualId = to.params.id
        this.manualVersion=to.params.manualVersion
        this.dataList()
      }
    }
  },
  methods: {
    dataList () {
      var params = new URLSearchParams()
      params.append('id', this.$route.params.id)
      params.append('manualVersion', this.$route.params.manualVersion)
      contentData(params).then(res => {
        this.listdata = res.data.data
        this.treeExpandIdList.push(this.listdata[0].id)
      })
      // axios({
      //   method: 'post',
      //   url: cmsServerUrl + 'cms/manual/directory/getChildrenList',
      //   data: params
      // }).then(res => {
      //   this.listdata = res.data.data
      //   this.treeExpandIdList.push(this.listdata[0].id)
      // })
    },
    // 附件上传
    onBeforeUpload(file) {
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();  
      var text=""               
      const docExt = fileExt === 'zip' 
      const isLimit = file.size / 1024 / 1024 < 20  
      if(!docExt) { 
        text="上传文件只能是 zip格式!";
        this.$message.error(text)
        return false;
      }  
      if (!isLimit) {  
        text="上传文件大小不能超过 20MB!";
        this.$message.error(text)
        return false;
      }  
      return true;
    },
    // 批量上传
    uploadFile (param) {
			  var formData = new FormData(); 
			  formData.append('file', param.file);
        formData.append('manualId', this.manualId);
        formData.append('manualVersion', this.manualVersion);
        contentImport(formData).then(res => {
          if (res.data.code === 100) {
            this.$message.success('上传成功')
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(function(error){
          if(error.response){
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          }else{
             console.log(error.message)
          }
        })
			 
        // axios({
        //   method: 'post',
        //   url: cmsServerUrl + "cms/manual/topic/batchImport",
        //   data: formData
        // }).then(res => {
        //   if (res.data.code === 100) {
        //      this.$message.success('上传成功')
        //   }else{
        //      this.$message.error(res.data.msg)
        //   }
        // }).catch(function(error){
        //   if(error.response){
        //     console.log(error.response.data)
        //     console.log(error.response.status)
        //     console.log(error.response.headers)
        //   }else{
        //      console.log(error.message)
        //   }
        // })
			 
		},
    handleNodeClick (treeNode) {
      console.log(treeNode)
      let level = treeNode.level
      let directoryId = treeNode.id
      let isLeaf = treeNode.isLeaf
      if(isLeaf==1){
        this.showEditArea=true
        this.showViewBtn=false
        this.directoryId =directoryId
        var params = '?id=' + directoryId
        contentDetail(params).then(res => {
          if(res.data.code==100){
            this.coder.setValue(res.data.data.topic)
          }
        })
        // axios.get(cmsServerUrl + 'cms/manual/topic/detail?id=' + directoryId , {
        //       params: {
        //       }
        //   }).then(res => {
        //     console.log(res)
        //       if(res.data.code==100){
        //          this.coder.setValue(res.data.data.topic)
        //       }
            
        //   })
      }else{
        this.showEditArea=false
        this.showViewBtn=true
      }
    },
    updateTopic(){
      var formData = new FormData(); 
			formData.append('directoryId', this.directoryId);
      formData.append('topicContent', this. coder.getValue());
      formData.append('action', 0);
      contentAdd(formData).then(res => {
        if (res.data.code === 100) {
          this.$message.success('保存成功')
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(function(error){
        if(error.response){
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }else{
          console.log(error.message)
        }
      })
        // axios({
        //   method: 'post',
        //   url: cmsServerUrl +  "cms/manual/topic/update",
        //   data: formData
        // }).then(res => {
        //   if (res.data.code === 100) {
        //      this.$message.success('保存成功')
        //   }else{
        //      this.$message.error(res.data.msg)
        //   }
        // }).catch(function(error){
        //   if(error.response){
        //     console.log(error.response.data)
        //     console.log(error.response.status)
        //     console.log(error.response.headers)
        //   }else{
        //      console.log(error.message)
        //   }
        // })
    },
    showAddVersionDlg(){
     this.dialogFormVisible = true
    },
    addTopic(){
      if(this.versionRemark==='' ||this.versionRemark.length===0) {
        this.$message.error('请输入版本备注')
        return false;
      }
      if(this.versionRemark.length > 200) {
        this.$message.error('版本备注最多只能输入200个字符')
        return false;
      }
      var formData = new FormData(); 
			formData.append('directoryId', this.directoryId);
      formData.append('topicContent', this.coder.getValue());
      formData.append('action', 1);
      formData.append('remark', this.versionRemark);
      contentAdd(formData).then(res => {
        if (res.data.code === 100) {
          this.$message.success('保存新版本成功')
          this.dialogFormVisible=false
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(function(error){
        if(error.response){
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }else{
          console.log(error.message)
        }
      })
    },
    heightArea () {
      setTimeout(function () {
        var paddingHeight =2*Number($('.layoutContainer').css('padding').split('p')[0])
        var allHeight = $('.elRow').outerHeight(true)
        var butHeight = $('.butArea').outerHeight(true)
        console.log(allHeight , butHeight , paddingHeight)
        var heightVal = allHeight - butHeight - paddingHeight
        $('.CodeMirror.cm-s-cobalt').css('height', heightVal)
      },1)
    },
    areaSize(){
      var _this = this
      _this.heightArea()
      window.addEventListener('resize',function(){
        _this.heightArea()
      })
    },
    // 初始化
    initialize () {
      // 初始化编辑器实例，传入需要被实例化的文本域对象和默认配置
      this.coder = CodeMirror.fromTextArea(this.$refs.textarea, this.options)
      // 编辑器赋值
      this.coder.setValue(this.value || this.code)
      // 支持双向绑定
      this.coder.on('change', (coder) => {
        this.code = coder.getValue()
        if (this.$emit) {
          this.$emit('input', this.code)
        }
      })
      // 尝试从父容器获取语法类型
      if (this.language) {
        // 获取具体的语法类型对象
        const modeObj = this.getLanguage(this.language)
        // 判断父容器传入的语法是否被支持
        if (modeObj) {
          this.mode = modeObj.label
        }
      }
    },
    // 获取当前语法类型
    getLanguage (language) {
      // 在支持的语法类型列表中寻找传入的语法类型
      return this.modes.find((mode) => {
        // 所有的值都忽略大小写，方便比较
        const currentLanguage = language.toLowerCase()
        const currentLabel = mode.label.toLowerCase()
        const currentValue = mode.value.toLowerCase()
        // 由于真实值可能不规范，例如 java 的真实值是 x-java ，所以讲 value 和 label 同时和传入语法进行比较
        return currentLabel === currentLanguage || currentValue === currentLanguage
      })
    },
    // 更改模式
    changeMode (val) {
      // 修改编辑器的语法配置
      this.coder.setOption('mode', `text/${val}`)
      // 获取修改后的语法
      const label = this.getLanguage(val).label.toLowerCase()
      // 允许父容器通过以下函数监听当前的语法值
      this.$emit('language-change', label)
    }
  },
  mounted () {
       this.manualId = this.$route.params.id
       this.manualVersion =this.$route.params.manualVersion
    // 初始化
    // obj.areaSize()
    this.dataList()
    this.initialize()
    this.areaSize()
  }
}
</script>
<style>
  .layoutContainer{
    /* height: 100%; */
    /* flex-grow: 1;
    display: flex;
    position: relative; */
  }
  /* .tableDetail,.elRow {
    height: 100%;
  } */
  .CodeMirror{
    flex-grow: 1;
    z-index: 1;
  }
  .CodeMirror-code{
    line-height: 19px;
  }
  .code-mode-select{
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 10px;
    max-width: 130px
  }
  .CodeMirror-vscrollbar{
    overflow: hidden;
  }
</style>
