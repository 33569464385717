<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="版本号">
          <!-- <el-input v-model="formInline.manualVersion"></el-input> -->
           <el-select v-model="formInline.manualVersion">
            <el-option v-for="(item, index) in versionList" :value="item.version" :label="item.version" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Topic名称">
          <el-input v-model="formInline.queryDirectorName" placeholder="请输入Topic名称"></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="formInline.queryAuditStatus">
            <el-option label="未审核" value="0"></el-option>
            <el-option label="审核通过" value="1"></el-option>
            <el-option label="审核驳回" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核时间">
          <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.beginTime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.endTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="审核人">
          <el-select v-model="formInline.queryAuditor">
            <el-option v-for="(item, index) in auditorList" :value="item.id" :label="item.realName" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-table style="width:100%" border :data="resultList">
        <el-table-column label="Topic名称" prop="directoryName" width="300"></el-table-column>
        <el-table-column label="审核状态" prop="status" width="200">
            <template slot-scope="{row}">
            <span v-if="row.status === 0">未审核</span>
            <span v-if="row.status === 1">审核通过</span>
            <span v-if="row.status === 2">审核驳回</span>
          </template>
        </el-table-column>
        <el-table-column label="驳回原因" prop="reason" width="400"></el-table-column>
        <el-table-column label="审核人" prop="auditorName" width="190"></el-table-column>
        <el-table-column label="审核时间" prop="createdTime" width="200" :formatter='dateFormat'></el-table-column>
        <el-table-column label="操作" prop="auditorName" width="350">
            <template slot-scope="{row}">
               <el-button type="primary" size="mini" @click="historyAudit(row)">历史审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog title="历史审核"  :visible.sync="dialogFormVisible" :close-on-click-modal="false">
        <el-table style="width:100%" border :data="historyList">
        <el-table-column label="审核状态" prop="status" width="100">
            <template slot-scope="{row}">
            <span v-if="row.status === 0">未审核</span>
            <span v-if="row.status === 1">审核通过</span>
            <span v-if="row.status === 2">审核驳回</span>
          </template>
        </el-table-column>
        <el-table-column label="驳回原因" prop="reason" width="350"></el-table-column>
        <el-table-column label="审核人" prop="auditorName" width="100"></el-table-column>
        <el-table-column label="审核时间" prop="createdTime" width="150" :formatter='dateFormat'></el-table-column>
      </el-table>
      </el-dialog>  
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import { auditDetailData, auditDetailVersion, auditDetailUser, auditDetailSearch, auditHistory } from '@/api/api.js'
import Pagination from '@/components/Pagination'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      manualId: '',
      manualVersion: '',
      formInline: {
        manualVersion: '',
        queryDirectorName: '',
        queryAuditStatus: '',
        beginTime: '',
        endTime: '',
        queryAuditor: ''
      },
      resultList: [],
      versionList: [],
      auditorList: [],
      historyList: [],
      dialogFormVisible:false,
      pagesize: 20,
      currentPage: 1,
      total: 0
    }
  },
  watch: {
    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.fullPath !== from.fullPath)  {
        this.dataList()
        this.getVersionList()
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        manualId: this.$route.params.id,
        manualVersion: this.$route.params.version
      }
      auditDetailData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    //获取当前手册全部版本列表
    getVersionList(){
      var params = '?manualId='+this.$route.params.id
      auditDetailVersion(params).then(res => {
        this.versionList = res.data.data
        this.formInline.manualVersion = this.$route.params.version
      })
    },
    // 用户列表
    getUserList () {
      auditDetailUser().then(res => {
        if (res.data.code === 100) {
          this.auditorList = res.data.data
        }
      })
    },

    onSubmit () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        manualId: this.manualId,
        manualVersion: this.formInline.manualVersion,
        createdUser: this.formInline.createdUser,
        status: this.formInline.status,
        beginTime: this.formInline.beginTime,
        endTime: this.formInline.endTime
      }
      auditDetailSearch().then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    historyAudit(row){
      let directoryId=row.directoryId
      var params = {
        directoryId: directoryId
      }
      auditHistory(params).then(res => {
        if(res.data.code==100){
          this.historyList = res.data.data
          this.dialogFormVisible=true
        }else{
         this.$message.error('查询历史审核数据失败')
        }
      }).catch(function(error){
        this.$message.error('查询历史审核数据失败')
      })
    },
    // 时间转换
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.manualId = this.$route.params.id
    this.manualVersion =this.$route.params.version
    this.getUserList()
    this.getVersionList()
    this.dataList()
  }
}
</script>
