<template>
<div class="texty">
  <!-- 打开测试窗口按钮 -->
	<el-button
	       type="primary"
	       icon="el-icon-plus"
	       size="mini"
	       @click="handleDialog"
	>测试弹窗
	</el-button>

	<!-- 使用组件-->
	<testDialog title="测试窗口" v-if="openDialog" ref="testDialog"/>
</div>
	
</template>

<script>
	// 引用组件
    import testDialog from "./About";
    // import {queryBizDefine} from "@/api/funds/routecenter/bizdefine";

    export default {
      name: 'texty',
	    // 注册组件
	    components: {testDialog},
	    data() {
		    return {
		        // 显示窗口
            openDialog: false,
            temp:{
              name:"1",
              sex:"2"
            }
		    };
	    },
	    methods: {
		    /** 查询业务列表 */
		    getList() {
		        this.loading = true;
		        // queryBizDefine(this.queryParams).then(response => {
		        //     this.bizList = response.data.rows;
		        //     this.total = response.data.total;
		        //     this.loading = false;
		        // });
		    },
		    // 按钮方法
		    handleDialog() {
          // roe
				this.openDialog = true;
				this.$nextTick(() => {
          // this.$refs.testDialog(this.temp)
				    this.$refs.testDialog.init(2);
				});
			}
		}
	};
</script>