<template>
  <div class="allproject">
    <div class="layTitle">
      <p class="titleName">全部项目</p>
    </div>
    <el-row>
      <el-col :span="24">
        <div class="projectDetail">
          <p class="pTitle">
            <span>进行中的项目</span>
          </p>
          <div class="allProjectList" v-cloak>
            <div class="projctCont"  v-for="(item, index) of projectList"  :key="index" >
              <div class="logo">
                <img src="http://t.cn/RCzsdCq" alt="">
              </div>
              <div class="content">
                <p>{{item.name}}</p>
                <p>项目负责人:{{item.principalName}}</p>
                <p>起止时间:{{dateFormat(item.createdTime)}}-{{dateFormat(item.establishTime)}}</p>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
// import { sysServerUrl,cmsServerUrl, obj } from '@/assets/js/common.js'
// import axios from 'axios'
import { project } from '@/api/api.js'
import $ from 'jquery'
export default {
  name: 'allproject',
  data () {
    return {
      projectList: [
      ]
    }
  },
  methods: {
    areaHeight () {
      var headHeight = $('.el-header').outerHeight(true)
      var layTitle = $('.layTitle').outerHeight(true)
      var tabHeight = $('.tabsTag').outerHeight(true)
      var footHeight = $('.el-footer').outerHeight(true)
      var title = $('.pTitle').outerHeight(true)
      var h = $(window).height() - headHeight - layTitle - tabHeight - footHeight - 2 * Number($('.projectDetail').css('marginTop').split('p')[0])
      $('.projectDetail').css('height', h + 'px')
      $('.allProjectList').css('height', h - title + 'px')
    },
    getProjectList(){
      project().then(res => {
        this.projectList = res.data.data
      })
      //  axios.get(cmsServerUrl + 'cms/project/list/all',{
      //   params: {}
      // }).then(res => {
      //   this.projectList = res.data.data
      // })
    },
    contentSize () {
      var _this = this
      _this.areaHeight()
      window.addEventListener('resize', function () {
        _this.areaHeight()
      })
    },
    dateFormat (cellValue) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    var _this = this
    _this.contentSize()
    _this.getProjectList()
  }
}
</script>
<style>
  /* header */
  .layTitle,.headerLay{
    background: #fff;
    margin: 0;
    padding: 15px 2%;
  }
  .layTitle .titleRank span{
    font-size: 16px;
  }
  .layTitle .titleRank span:first-child{
    color:#999;
  }
  .layTitle .titleName{
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
  }
  /* 内容 */
  .projectDetail{
    margin: 15px;
    background: #fff;
    overflow: hidden;
  }
  .projectDetail .pTitle{
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
  }
  .allProjectList {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    overflow-y: auto;
  }
  /* 滚动条 */
  .allProjectList::-webkit-scrollbar{
    width: 4px;
  }
  .allProjectList::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.1)
  }
  .allProjectList::-webkit-scrollbar-track{
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgba(53,51,51,0.1)
  }
  .allProjectList .projctCont{
    width: 20%;
    padding: 3% 1.5%;
    border-left: 1px solid #F0F0F0;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 30px;
    color: #666;
  }
  .allProjectList .projctCont .logo{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
  }
  .allProjectList .projctCont .logo img{
    width: 100%
  }
  .allProjectList .content{
    flex: 1;
    overflow: hidden;
  }
  .allProjectList .content p:first-child{
    font-weight: bold;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
