<template>
  <div class="layoutContainer">
    <el-button type="primary" @click="ShowImportDlg">批量导入数据包</el-button>
    <el-table style="width:100%" border  :data="resultList"  :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
      <el-table-column label="手册类型" prop="manualTypeName" width="180"></el-table-column>
      <el-table-column label="文件名" prop="zipName" width="210"></el-table-column>
      <el-table-column label="文件路径" prop="zipPath" width="350"></el-table-column>
      <el-table-column label="处理状态" prop="status" width="120">
        <template slot-scope="{row}">
          <span v-if="row.status === 1">未处理</span>
          <span v-if="row.status === 2">处理中</span>
          <span v-if="row.status === 3">处理成功</span>
          <span v-if="row.status === 4">处理失败</span>
        </template>
      </el-table-column>
      <el-table-column label="处理结果" prop="verifyResult" width="450"></el-table-column>
      <el-table-column label="更新人" prop="updatedUserName" width="120"></el-table-column>
      <el-table-column label="更新时间" prop="updatedTime" width="120"  :formatter='dateFormat'></el-table-column>
      <el-table-column label="操作" fixed="right" width="100">
        <template slot-scope="{row}">
          <el-button type="primary" size="mini" @click="handleDetail(row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form v-if="dialogStatus === 'upload'">
        <div class="upload-area">
          <el-upload
            class="upload-demo inline-block margin-left-10 margin-right-10"
            ref="elUpload"
            action="#"
            :show-file-list="false"
            multiple
            :limit="1"
            :before-upload="onBeforeUpload"
          >
            <el-button size="min" icon="el-icon-upload" type="primary" style="" v-show="showUploadBtn">批量上传</el-button>
            <el-progress style="width:350px;margin-top:100px;margin-left:10px"  v-show="showUploadProcess" color="green" type="line" :text-inside="true" :stroke-width="20" :percentage="percentage"></el-progress>
          </el-upload>
        </div>
      </el-form>
      <el-form v-if="dialogStatus === 'check'">
        <el-form-item label="手册类型" prop="manualTypeName" :label-width="formLabelWidth">
          <el-input v-model="temp.manualTypeName" readonly></el-input>
        </el-form-item>
        <el-form-item label="文件名" prop="zipName" :label-width="formLabelWidth">
          <el-input v-model="temp.zipName" readonly></el-input>
        </el-form-item>
        <el-form-item label="文件路径" prop="zipPath" :label-width="formLabelWidth">
          <el-input v-model="temp.zipPath" readonly></el-input>
        </el-form-item>
        <el-form-item label="处理状态" prop="status" :label-width="formLabelWidth">
          <el-input v-if="temp.status === 1" value="未处理"></el-input>
          <el-input v-if="temp.status === 2" value="处理中"></el-input>
          <el-input v-if="temp.status === 3" value="处理成功"></el-input>
          <el-input v-if="temp.status === 4" value="处理失败"></el-input>
        </el-form-item>
        <el-form-item label="处理结果" prop="verifyResult" :label-width="formLabelWidth">
          <el-input v-model="temp.verifyResult" readonly></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { manageData, manageFile, manageProgress, manageImport } from '@/api/api.js'
import axios from 'axios'
import SparkMD5 from 'spark-md5'
/** 计算文件md5值
 * @param file 文件
 * @param chunkSize 分片大小
 * @returns Promise
 */
function getmd5(file, chunkSize) {
  return new Promise((resolve, reject) => {
    let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    let chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let spark = new SparkMD5.ArrayBuffer();
    let fileReader = new FileReader();
    fileReader.onload = function(e) {
        spark.append(e.target.result);
        currentChunk++;
        if (currentChunk < chunks) {
            loadNext();
        } else {
            let md5 = spark.end();
            resolve(md5);
            //  console.log(md5);
        }
    };
    fileReader.onerror = function(e) {
        reject(e);
    };
    function loadNext() {
        let start = currentChunk * chunkSize;
        let end = start + chunkSize;
        if (end > file.size){
            end = file.size;
        }
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    loadNext();
  });
}
export default {
  name: 'layoutContainer',
  components: {Pagination},
  data () {
    return {
      formLabelWidth: '100px',
      temp:{
        manualTypeName: '',
        zipName:'',
        zipPath:'',
        status:'',
        verifyResult:'',
        updateUserNaem:'',
        updatedTime:''
      },
      resultList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        upload: '批量上传',
        check: '查看',
      },
      uploadUrl: '',
      fileList: [],
      //切片文件
      fileShard:{},
      //当前文件
      curFile:{},
        //文件分割的开始位置
      start: 0,
      //文件分割的结束位置
      end: 0,
      //文件大小
      fileSize: 0,
      fileKey: '',
      fileShardIndex: 0,
      fileShardTotal: 0,
      fileShardSize: 0,
      switchC: false,
      percentage: 0,
      showUploadBtn:false,
      showUploadProcess:false,
    }
  },
  methods:{
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        manualId: this.$route.params.id
      }
      manageData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
      //  axios.get(cmsServerUrl + 'cms/manual/verify/list', {
      //   params: {
      //     page: this.currentPage,
      //     limit: this.pagesize,
      //     manualId: this.$route.params.id
      //   }
      // }).then(res => {
      //   this.total = res.data.total
      //   this.resultList = res.data.data
      // })

    },
    ShowImportDlg(){
      this.showUploadBtn=true
      this.percentage=0;
      this.dialogStatus = 'upload'
      this.dialogFormVisible = true
    },
    onBeforeUpload(file){
      let text=""
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();              
      const zipExt = fileExt === 'zip' 
      const isLimit = file.size / 1024 / 1024 < 1024
      if(!zipExt) { 
        text="上传文件只能是 zip格式!";
        this.$message.error(text)
        return false;
      }  
      if (!isLimit) {  
        text="上传文件大小不能超过 1GB!";
        this.$message.error(text)
        return false;
      } 
      this.fileShardSize = 1*1024 * 1024; //每片文件大小
      //点击后隐藏上传按钮 ，防止重复点击
      //$("#fileUpload").css('visibility','hidden');
      this.showUploadBtn=false
      this.showUploadProcess=true
      var _this=this
      getmd5(file,_this.fileShardSize).then(e =>{
        _this.switchC=false;
        _this.fileShardIndex=1;//分片索引
        _this.curFile=file;
        _this.fileKey=e;
        _this.fileSize=file.size;
        _this.fileShardTotal=Math.ceil(file.size/_this.fileShardSize);//分片总数
        var fileFullName=file.name;
        _this.fileSuffix = fileFullName.substr(fileFullName.lastIndexOf('.') + 1);
        _this.fileName = fileFullName.substr(0, fileFullName.lastIndexOf('.'));

        //上传参数
        var params =  new FormData()
        params.append('fileName', _this.fileName)
        params.append('fileShardTotal', _this.fileShardTotal)
        params.append('fileKey', _this.fileKey)
        params.append('fileSuffix', _this.fileSuffix)
        params.append('fileShardSize', _this.fileShardSize)
        params.append('fileSize', _this.fileSize)
        params.append('fileFlag', "manual")
        _this.updateProgress(file,params)
      })
    },
    // 批量上传
    uploadFile (formData) {
      var _this=this
      manageFile(formData).then(res => {
        if(res.data.code==200){
          //上传分片完成
          if(res.data.shardIndex<_this.fileShardTotal){
            _this.fileShardIndex=_this.fileShardIndex+1;
            _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
            _this.end =Math.min(_this.curFile.size,_this.start+_this.fileShardSize);
            _this.fileSize=_this.curFile.size;
            var params =  new FormData()
            params.append('fileName', _this.fileName)
            params.append('fileShardTotal', _this.fileShardTotal)
            params.append('fileKey', _this.fileKey)
            params.append('fileSuffix', _this.fileSuffix)
            params.append('fileShardSize', _this.fileShardSize)
            params.append('fileSize', _this.fileSize)
            params.append('fileFlag', "manual")           
            params.append('fileShardIndex', _this.fileShardIndex)
                    
            var fileShardtem=_this.curFile.slice(_this.start,_this.end);//从文件中获取当前分片数据
            let fileReader = new FileReader();
            //异步读取本地文件分片数据并转化为base64字符串
            fileReader.readAsDataURL(fileShardtem); 
            //本地异步读取成功后，进行上传
            fileReader.onload = function (e) {
              let  base64str = e.target.result;
              params.append('base64', base64str)
              _this.uploadFile(params)
            }     
            let perentNum=Math.ceil(this.fileShardIndex * 100 / this.fileShardTotal) 
            if(perentNum>100){
              this.percentage=100
            }else{
              this.percentage=perentNum
            }
          }
        }else if(res.data.code==100){
          var fileId= res.data.id
          //上传完成
          _this.percentage=100
          _this.switchC=true
          // this.$message({
          //   message: '极速上传成功',
          //   type: 'success'
          // })
          _this.batchImport(fileId)              
        }
        console.log(this.percentage)
      }).catch(function(error){
        if(error.response){
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }else{
          console.log(error.message)
        }
      }) 
      // axios({
      //   method: 'post',
      //   url: sysServerUrl+"sys/upload/procFile",
      //   data: formData
      // }).then(res => {
      //   if(res.data.code==200){
      //     //上传分片完成
      //     if(res.data.shardIndex<_this.fileShardTotal){
      //       _this.fileShardIndex=_this.fileShardIndex+1;
      //       _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
      //       _this.end =Math.min(_this.curFile.size,_this.start+_this.fileShardSize);
      //       _this.fileSize=_this.curFile.size;
      //       var params =  new FormData()
      //       params.append('fileName', _this.fileName)
      //       params.append('fileShardTotal', _this.fileShardTotal)
      //       params.append('fileKey', _this.fileKey)
      //       params.append('fileSuffix', _this.fileSuffix)
      //       params.append('fileShardSize', _this.fileShardSize)
      //       params.append('fileSize', _this.fileSize)
      //       params.append('fileFlag', "manual")           
      //       params.append('fileShardIndex', _this.fileShardIndex)
                    
      //       var fileShardtem=_this.curFile.slice(_this.start,_this.end);//从文件中获取当前分片数据
      //       let fileReader = new FileReader();
      //       //异步读取本地文件分片数据并转化为base64字符串
      //       fileReader.readAsDataURL(fileShardtem); 
      //       //本地异步读取成功后，进行上传
      //       fileReader.onload = function (e) {
      //         let  base64str = e.target.result;
      //         params.append('base64', base64str)
      //         _this.uploadFile(params)
      //       }     
      //       let perentNum=Math.ceil(this.fileShardIndex * 100 / this.fileShardTotal) 
      //       if(perentNum>100){
      //         this.percentage=100
      //       }else{
      //         this.percentage=perentNum
      //       }
      //     }
      //   }else if(res.data.code==100){
      //     var fileId= res.data.id
      //     //上传完成
      //     _this.percentage=100
      //     _this.switchC=true
      //     // this.$message({
      //     //   message: '极速上传成功',
      //     //   type: 'success'
      //     // })
      //     _this.batchImport(fileId)              
      //   }
      //   console.log(this.percentage)
      // }).catch(function(error){
      //   if(error.response){
      //     console.log(error.response.data)
      //     console.log(error.response.status)
      //     console.log(error.response.headers)
      //   }else{
      //     console.log(error.message)
      //   }
      // })     
    },
    updateProgress(file,params){
      var _this= this
      var param = new URLSearchParams()
      param.append('shardKey', _this.fileKey)
      manageProgress(param).then(res => {
        if(res.data.code==200){
          //新文件
          _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
          _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
          _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        }else if(res.data.code==220){
          _this.fileShardIndex=res.data.ShardIndex;
          //有上传未完成的
          _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
          _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
          _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        }else if (res.data.code==240){
          //急速上传 
          var fileId= res.data.id
          _this.percentage=100
          _this.switchC=true
          console.log(this.percentage)
          // this.$message({
          //   message: '极速上传成功',
          //   type: 'success'
          // })
          _this.batchImport(fileId)
          return false;
        }
            
        //读取base64str
        let fileReader = new FileReader();
        //异步读取本地文件分片并转化为base64字符串
        fileReader.readAsDataURL(_this.fileShard); 
        //本地异步读取成功，进行上传
        fileReader.onload=function (e){
          let  base64str=e.target.result;
          params.append('base64', base64str)
          params.append('fileShardIndex', _this.fileShardIndex)
          if(_this.switchC==false){
            _this.uploadFile(params)
          }
        }
      })
      // axios({
      //   method: 'POST',
      //   url:  sysServerUrl+"sys/upload/checkProgress",
      //   data: param
      // }).then(res => {
      //   if(res.data.code==200){
      //     //新文件
      //     _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
      //     _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
      //     _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
      //   }else if(res.data.code==220){
      //     _this.fileShardIndex=res.data.ShardIndex;
      //     //有上传未完成的
      //     _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
      //     _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
      //     _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
      //   }else if (res.data.code==240){
      //     //急速上传 
      //     var fileId= res.data.id
      //     _this.percentage=100
      //     _this.switchC=true
      //     console.log(this.percentage)
      //     // this.$message({
      //     //   message: '极速上传成功',
      //     //   type: 'success'
      //     // })
      //     _this.batchImport(fileId)
      //     return false;
      //   }
            
      //   //读取base64str
      //   let fileReader = new FileReader();
      //   //异步读取本地文件分片并转化为base64字符串
      //   fileReader.readAsDataURL(_this.fileShard); 
      //   //本地异步读取成功，进行上传
      //   fileReader.onload=function (e){
      //     let  base64str=e.target.result;
      //     params.append('base64', base64str)
      //     params.append('fileShardIndex', _this.fileShardIndex)
      //     if(_this.switchC==false){
      //       _this.uploadFile(params)
      //     }
      //   }
      // })
    },
    batchImport(fileId){
      var params = {
        manualId: this.manualId,
        fileId: fileId
      }
      manageImport(params).then(res => {
        if(res.data.code==100){
          this.dialogFormVisible = false
          this.$message({
            duration:10000,
            message: '上传已完成,系统正在解析处理数据，请稍等...',
            type: 'success'
          })
          this.dataList()
        } 
      })
      // axios.get(cmsServerUrl +"cms/manual/verify/procPackage", {
      //   params: {
      //     manualId: this.manualId,
      //     fileId: fileId
      //   }
      // }).then(res => {
      //   //alert(res.data.code);
      //   if(res.data.code==100){
      //     this.dialogFormVisible = false
      //     this.$message({
      //       duration:10000,
      //       message: '上传已完成,系统正在解析处理数据，请稍等...',
      //       type: 'success'
      //     })
      //     this.dataList()
      //   }    
      // })
    },
    resetTemp(){
      this.temp = {
        manualTypeName: '',
        zipName:'',
        zipPath:'',
        status:'',
        verifyResult:'',
        updateUserNaem:'',
        updatedTime:''
      }
    },
       // 查看
    handleDetail (row) {
      console.log(row)
      // this.resetTemp()
      // this.dialogStatus = 'detail'
      // this.dialogFormVisible = true
      // axios.get(cmsServerUrl + 'cms/manual/info?id=' + row.id).then(res => {
      //   this.time = res.data.data
      //   this.temp = res.data.data.manual
      // })
      this.resetTemp()
      this.temp = Object.assign({}, row)
      // this.getStatusCode()
      this.dialogFormVisible = true
      this.dialogStatus = 'check'
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.manualId =this.$route.params.id
    this.uploadUrl = cmsServerUrl+"sys/upload/procFile"
    this.dataList()
  }
}
</script>
