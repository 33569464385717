<template>
  <div class="workbench">
    <div class="layTitle">
      <!-- <p class="titleRank"><span>{{$t('dashboard.layTitle')}} / </span><span>{{$t('dashboard.subTitle')}}</span></p> -->
      <p class="titleName">{{$t('dashboard.subTitle')}}</p>
    </div>
    <div class="headerLay">
      <el-row>
        <el-col :span="16" class="left">
          <div class="leftLogo">
            <img v-if="baseData.headimgurl == null" src="http://t.cn/RCzsdCq" alt="">
            <img v-if="baseData.headimgurl" :src="userInfo.headimgurl" alt="">
          </div>
          <div class="leftContent">
            <p class="titleOne">{{toGreet}} {{baseData.username}}，{{$t('dashboard.tipTitle')}}</p>
            <!-- <p class="titleTwo">{{toGreet}} {{baseData.username}}，{{$t('dashboard.tipTitle')}}</p> -->
          </div>
        </el-col>
        <el-col :span="8" class="right">
          <div class="colRight">
            <div>
              <p>{{$t('dashboard.myProject')}}</p>
              <p v-text="type === 'cn' ? '6' : 'six'"></p>
            </div>
            <div>
              <p>{{$t('dashboard.Incomplete')}}</p>
              <p v-text="type === 'cn' ? '5' : 'five'"></p>
              <p>{{$t('dashboard.defer')}} <span v-text="type === 'cn' ? '2' : 'two'"></span></p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="container">
      <el-row :gutter="20">
        <el-col :span="14">
          <div class="projectContent">
            <div class="project">
              <p class="pTitle">
                <span>{{$t('dashboard.pTitle')}}</span>
                <span>
                  <a @click="allClick($t('dashboard.rTitle'), '/allProject')">{{$t('dashboard.rTitle')}}</a>
                </span>
              </p>
              <div class="projectList">
                 <template  v-for="(item, index) of projectList"  > 
                <div class="listContainer" v-if="index < 9" :key="index">
                  <div class="logo">
                    <img src="http://t.cn/RCzsdCq" alt="">
                  </div>
                  <div class="content">
                    <p>{{item.name}}</p>
                    <p>项目负责人:{{item.principalName}}</p>
                    <p>起止时间:{{dateFormat(item.createdTime)}}~{{dateFormat(item.establishTime)}}</p>
                  </div>
                </div>
                 </template>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="10" aa="4">
          <div class="rightContent">
            <div class="dynamic">
              <p class="dTitle">
                <span>{{$t('dashboard.dTitle')}}</span>
              </p>
              <div class="dynamicDetail">
                <div class="dynamicList" v-for="(item, index) of dynamicList" :key="index">
                  <div class="logo">
                    <div>
                      <img src="http://t.cn/RCzsdCq" alt="">
                    </div>
                  </div>
                  <div class="subStance">
                    <p>
                      <span>{{item.operatorName}}</span>
                      <span>{{item.content}}{{item.targetName}}</span>
                    </p>
                    <p>{{ dateFormatTime(item.operateTime)}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { obj } from '@/assets/js/common.js'
import { project,dynamic, getUserInfo } from '@/api/api.js'
// import axios from 'axios'
import $ from 'jquery'

export default {
  name: 'workbench',
  data () {
    return {
      toGreet: '',
      userId: '',
      type: '',
      baseData: [],
      projectList: [],
      dynamicList: []
    }
  },
  methods: {
    // 进行中的项目
    projectData () {
      project().then(res => {
        this.projectList = res.data.data
      })
    },
    // 动态
    dynamicData () {
       dynamic().then(res => {
        this.dynamicList = res.data.data
      })
      // axios.get('/data/' + localStorage.lang + '/dynamic.json').then(res => {
      //   this.dynamicList = res.data
      // })
    },
    // 问候语
    menuList () {
      const timeNow = new Date()
      // 获取当前小时
      const hours = timeNow.getHours()
      // 设置默认文字
      var text = ''
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
        text = this.$t('dashboard.oneGreet')
      } else if (hours > 10 && hours <= 14) {
        text = this.$t('dashboard.twoGreet')
      } else if (hours > 14 && hours <= 18) {
        text = this.$t('dashboard.threeGreet')
      } else if (hours > 18 && hours <= 24) {
        text = this.$t('dashboard.fourGreet')
      }
      this.toGreet = text
    },
    // 获取用户信息
    userInfo () {
      var _this = this
      getUserInfo().then(res =>{
        _this.baseData = res.data.data
      })
      // axios.get(sysServerUrl + 'sys/user/getCurrentInfo').then(res => {
      //   _this.baseData = res.data.data
      // })
    },
    // 获取内容区域高度
    areaHeight () {
      var headHeight = $('.el-header').outerHeight(true)
      var layTitle = $('.layTitle').outerHeight(true)
      var headerLay = $('.headerLay').outerHeight(true)
      var tabHeight = $('.tabsTag').outerHeight(true)
      var footHeight = $('.el-footer').outerHeight(true)
      if ($('.container').css('marginTop') !== undefined) {
        var topHeight = 2 * Number($('.container').css('marginTop').split('p')[0])
      } else {
        topHeight = 0
      }
      var h = $(window).height() - headHeight - layTitle - headerLay - tabHeight - footHeight - topHeight
      $('.container').css('height', h + 'px')
      // 获取进行中的项目内容改的和动态的内容高度
      $('.projectList').css('max-height', h - $('.projectContent .pTitle').height())
      $('.dynamicDetail').css('height', h - $('.projectContent .pTitle').height())
    },
    sizeDetail () {
      var _this = this
      _this.areaHeight()
      window.addEventListener('resize', function () {
        _this.areaHeight()
      })
    },
    allClick (name, url) {
      obj.tabsClick(name, url)
      this.$router.push(url)
    },
    dateFormat (cellValue) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    },
    dateFormatTime (cellValue) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''

        const Hour =  date.getHours() < 10 ? '0' + date.getHours() + '' : date.getHours() + '' 
        const Minute = date.getMinutes() < 10 ? '0' + date.getMinutes() + '' : date.getMinutes() + ''  
        const Second = date.getSeconds() < 10 ? '0' + date.getSeconds() + '' : date.getSeconds() + ''  
        return Y + M + D + " "+ Hour +":"+ Minute +":"+ Second
      }
    }
  },
  mounted () {
    var _this = this
    _this.type = localStorage.lang
    // obj.getUserToken()
    _this.projectData()
    _this.dynamicData()
    setTimeout(function () {
      _this.userId = sessionStorage.getItem('userId')
      _this.userInfo()
    }, 70)
    _this.menuList()
    _this.sizeDetail()
  }
}
</script>
<style>
  /* header */
  .workbench{
    background: rgb(240, 242, 245);
  }
  .layTitle,.headerLay{
    background: #fff;
    margin: 0;
    padding: 15px 2%;
  }
  .layTitle .titleRank span{
    font-size: 16px;
  }
  .layTitle .titleRank span:first-child{
    color:#999;
  }
  .layTitle .titleName{
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
  }
  .headerLay{
    padding: 4px 2%;
  }
  .headerLay .left{
    display: flex;
    align-items: center;
    margin-top: 3%;
  }
  .headerLay .left div:first-child{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  .headerLay .left:first-child div img{
    width: 100%;
    height: 100%;
  }
  .leftContent{
    flex: 1;
  }
  .titleOne{
    font-size: 22px;
  }
  .titleTwo{
    font-size: 14px;
    color:#666;
    margin-top: 10px;
  }
  .colRight {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    font-size: 16px;
  }
  .colRight div {
    line-height: 2;
    padding-left: 20px;
  }
  .colRight div p:nth-child(2) {
    font-size: 28px;
  }
  .colRight div p:nth-child(3) {
    font-size: 14px;
    color: red;
    padding: 1px 5px;
    border-radius: 5px;
    background: rgba(255,0,0,0.1);
    display: inline-block;
  }
  /* 内容 */
  .container{
    margin: 15px 10px 0;
    padding-bottom: 14px;
    overflow: hidden;
  }
  .projectContent{
    margin-right: -10px;
  }
  .projectContent,.rightContent{
    background: #fff;
    border-radius: 10px;
  }
  /* 标题 */
  .project .pTitle,.dynamic .dTitle{
    font-size: 17px;
    border-bottom: 1px solid #f0f0f0;
    height: 45px;
    line-height: 45px;
    background: #fff;
    border-radius: 10px 10px 0 0;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
  }
  .project .pTitle a{
    /* color: #1890ff !important; */
    color: var(--themeColor) !important;
    cursor: pointer;
  }
  /* 项目 */
  .projectList{
    display: flex;
    flex-flow: row wrap;
  }
  .projectList, .dynamicDetail {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .projectList .listContainer {
    width: 33.3%;
    padding: 20px 2%;
    border-left: 1px solid #F0F0F0;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    box-sizing: border-box;
  }
  .projectList .listContainer .logo{
    width: 28px;
    height: 28px;
    border-radius: 50%;
     overflow: hidden;
    margin-right: 8px; 
  }
  .projectList .listContainer .logo img{
    width: 100%;
  }
  .projectList .listContainer .content{
    font-size: 14px;
    line-height: 30px;
    color: #666;
    flex: 1;
    overflow: hidden;
  }
  .projectList .listContainer .content p:first-child{
    font-weight: bold;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  /* 滚动条 */
  .projectList::-webkit-scrollbar,.dynamicDetail::-webkit-scrollbar{
    width: 4px;
  }
  .projectList::-webkit-scrollbar-thumb,.dynamicDetail::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.1)
  }
  .projectList::-webkit-scrollbar-track,.dynamicDetail::-webkit-scrollbar-track{
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgba(53,51,51,0.1)
  }
  /* 动态 */
  .dynamic .dynamicList {
    padding: 15px 0;
    margin: 0 3%;
    display: flex;
    border-bottom: 1px solid #f0f0f0;
  }
  .dynamicList .logo div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  .dynamic .dynamicList .logo img {
    width: 100%;
    height: 100%;
  }
  .dynamic .dynamicList .subStance {
    flex: 1;
    font-size: 15px;
    overflow: hidden;
  }
  .dynamic .dynamicList .subStance p {
    display: flex
  }
  .dynamic .dynamicList .subStance p span:first-child {
    /* color: #1890ff; */
    color: var(--themeColor);
    margin-right: 5px;
    line-height: 1.6;
    white-space: nowrap;
  }
  .dynamic .dynamicList .subStance p span:last-child {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dynamic .dynamicList .subStance p:last-child {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
</style>
