<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="版本号" prop="manualVersion">
          <el-select v-model="formInline.manualVersion">
            <el-option v-for="(item, index) in versionList" :key="index" :value="item.version" :label="item.version"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片名称" prop="queryimgName">
          <el-input v-model="formInline.queryimgName" placeholder="请输入图片名称"></el-input>
        </el-form-item>
        <el-form-item label="图片批注" prop="queryNotes">
          <el-input v-model="formInline.queryNotes" placeholder="请输入图片批注"></el-input>
        </el-form-item>
        <el-form-item label="提交时间">
          <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.begintime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.endtime"></el-date-picker>
        </el-form-item>
        <el-form-item label="提交人" prop="queryUploader">
          <el-select v-model="formInline.queryUploader">
            <el-option v-for="(item, index) of uploaderList" :key="index" :label="item.realName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-upload
          class="upload-demo inline-block"
          ref="batchUpload"
          action="#"
          :show-file-list="false"
            multiple
          :limit="1"
          :before-upload="onBeforeBatchUpload"
          :http-request="uploadZipFile"
          >
          <el-button size="min" icon="el-icon-upload" type="primary">批量导入</el-button>
      </el-upload>
      <!-- <el-button type="primary" icon="el-icon-plus" @click="headelImport()">批量导入</el-button> -->
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="图片" prop="path" width="230">
          <template slot-scope="{row}">
            <img :src="url + 'cms/manual/image/display?filePath=' + row.path + '/' + row.name" style="width:50px">
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" width="180"></el-table-column>
        <el-table-column label="类型" prop="type" width="180"></el-table-column>
        <el-table-column label="批注" prop="notes" width="350">
            <template inline-template slot-scope="scope">
            <el-input  v-model="scope.row.notes" type="textarea"  rows="2"
              @change="updateNotes(scope.$index,scope.row,$event)">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="上传人" prop="uploaderName" width="190"></el-table-column>
        <el-table-column label="上传时间" prop="createdTime" width="190" :formatter="dateFormat"></el-table-column>
        <el-table-column label="操作" fixed="right" width="275">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="showImageEditDlg(row)">编辑</el-button>
            <el-button type="primary" size="mini" style="background:#ff5722" @click="Delete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
 
      <el-dialog :title="textMap[dialogStatus]"   :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form  ref='dataForm' label-position="center"  :rules="fromTemp" prop=“imageInfo :model="imageInfo" >
        <el-form-item label="图片名称:" prop="name" :label-width="formLabelWidth">
          <el-input  v-model="imageInfo.name" aria-required="true"></el-input>
        </el-form-item>
        <el-form-item label="图片批注:" prop="notes" :label-width="formLabelWidth">
          <el-input type="textarea"  rows="3" v-model="imageInfo.notes" ></el-input>
        </el-form-item>
         <el-form-item label="图片：" prop="path" :label-width="formLabelWidth">
              <el-upload
                class="upload-demo"
                style="max-width: 379px;"
                :action="uploadUrl"
                :headers="importHeader"
                :on-success="handleOnSuccess"
                :on-remove="handleOnRemove"
                :before-remove="beforeOnRemove"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleOnExceed"
                multiple
                :limit="1"
                :file-list="imgList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                list-type="picture"
              >
                <el-button size="min" icon="el-icon-upload" type="primary">选择图片</el-button>
              </el-upload>
          </el-form-item>
        <el-form-item :label-width="formLabelWidth" style=" text-align:center;">
          <el-button type="primary" @click="updateImageInfo" >
            立即提交
          </el-button>
          <el-button @click="dialogFormVisible = false" >
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { detailData, detailSearch, detailVersion, detailUser, detailImport, detailUpdate, detailNotes } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        manualVersion: '',
        queryimgName: '',
        queryNotes: '',
        begintime: '',
        endtime: '',
        queryUploader: ''
      },
      imageInfo: {
        id: '',
        name: '',
        notes: '',
        path: ''
      },
      url: '',
      fileList: [],
      versionList: [],
      uploaderList: [],
      resultList: [],
      formLabelWidth: '100px',
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        import: '批量导入',
        edit: '编辑图片'
      },
      manualId: '',
      manualVersion: '',
      pagesize: 10,
      currentPage: 1,
      total: 0,
      uploadUrl: '',
      imgList: [],
      fromTemp: {
        name: [{ required: true, message: '图片名称不能为空', trigger: 'blur' }],
        notes: [{ required: true, message: '图片备注不能为空', trigger: 'blur' }]
      },
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token }
    }
  },
   watch: {
    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.fullPath !== from.fullPath) {
          this.url = cmsServerUrl
          this.manualId = this.$route.params.manualId
          this.manualVersion = this.$route.params.manualVersion
          this.dataList()
          this.getVersionList()
          this.getUserList()
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var param = {
        page: this.currentPage,
        limit: this.pagesize,
        manualId: this.manualId,
        manualVersion: this.manualVersion
      }
      detailData(param).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    //获取当前手册全部版本列表
    getVersionList(){
      var param = '?manualId=' + this.manualId
      detailVersion(param).then(res => {
        this.versionList = res.data.data
        this.formInline.manualVersion = this.manualVersion
      })
    },
    // 用户列表
    getUserList () {
      detailUser().then(res => {
        if (res.data.code === 100) {
          this.uploaderList = res.data.data
        }
      })
    },

    // 批量导入
    onBeforeBatchUpload(file) {
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();  
      var text=""               
      const docExt = fileExt === 'zip' 
      const isLimit = file.size / 1024 / 1024 < 20  
      if(!docExt) { 
        text="上传文件只能是 zip格式!";
        this.$message.error(text)
        return false;
      }  
      if (!isLimit) {  
        text="上传文件大小不能超过 20MB!";
        this.$message.error(text)
        return false;
      }  
      return true;
    },
    // 批量导入上传
    uploadZipFile (param) {
			var formData = new FormData(); 
			formData.append('file', param.file);
      formData.append('manualId', this.manualId);
      formData.append('manualVersion', this.manualVersion);
      detailImport(formData).then(res => {
        if (res.data.code === 100) {
          this.$message.success('导入成功')
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(function(error){
        if(error.response){
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }else{
          console.log(error.message)
        }
      })
		},

    showImageEditDlg(row){
      this.imageInfo.id=row.id
      this.imageInfo.name=row.name
      this.imageInfo.notes=row.notes
      this.imageInfo.path= row.path
      this.imgList=[]
      var img = {url: sysServerUrl + 'cms/manual/image/display?filePath=' +  this.imageInfo.path+"/"+this.imageInfo.name }
      this.imgList.push(img)
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
    },
    updateImageInfo(){
      if(this.imgList.length==0){
        this.$message.error('图片不能为空，请选择图片')
        return  false
      }
      var params = new URLSearchParams()
      params.append('id', this.imageInfo.id)
      params.append('name', this.imageInfo.name)
      params.append('path',this.imageInfo.path)
      params.append('notes', this.imageInfo.notes)
      detailUpdate(params).then(res=>{
        if(res.data.code==100){
          this.$message.success('修改图片成功')
          this.dialogFormVisible=false
          this.dataList()
        }
      }).catch(function(error){
        console.log(error.message)
        this.$message.error('修改图片失败')
      })
    },
    updateNotes(index,row,value){
      var params = new URLSearchParams()
      params.append('id', row.id)
      params.append('manualId', this.manualId)
      params.append('manualVersion',this.manualVersion)
      params.append('notes',value)
      detailNotes(params).then(res => {
        if(res.data.code==100){
          this.$message.success('修改批注成功')
        }
      })
    },

    handleOnSuccess (res, obj) {
      //var file = {name: res.data.fileName, url: res.data.fileUrl}
      
      this.imageInfo.path = res.data.fileUrl
      this.imgList=[]
      var img = {url:  sysServerUrl + 'sys/upload/display?filePath=' +  this.imageInfo.path }
      this.imgList.push(img)

      
    },
    beforeOnRemove(file, fileList) {
        return this.$confirm(`确定移除当前图片？`);
    },
    handleOnRemove(file,fileList){
      console.log(file)
      console.log(fileList)
    },
    beforeAvatarUpload (file) {
      const extension = file.name.split('.')[1] === 'png'
      const extension2 = file.name.split('.')[1] === 'jpg'
      const extension3 = file.name.split('.')[1] === 'jpeg'
      const extension4 = file.name.split('.')[1] === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!extension && !extension2 && !extension3 && !extension4) {
        this.$message.warning('上传图片只能是 png、jpg、jpeg、gif格式!')
      }
      if (!isLt2M) {
        this.$message.warning('上传图片大小不能超过 2MB!')
      }
    },
    handleOnExceed (files, fileList) {
      this.$message.warning(`当前限制选择1张图片，本次选择了 ${files.length} 张图片，共选择了 ${files.length + fileList.length} 张图片`)
    },

    // 重置
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
    },
    onSubmit () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        manualId: this.manualId,
        manualVersion: this.manualVersion,
        createdUser: this.formInline.queryUploader,
        beginTime: this.formInline.beginTime,
        endTime: this.formInline.endTime
      }
      detailSearch(params).then(res => {
        this.total = res.data.count
        this.resultList = res.data.data
      })
    },

    // 导入
    headelImport () {
      this.dialogStatus = 'import'
      this.dialogFormVisible = true
    },

    // 编辑
    handeledit (row) {
    },
    // 删除
    Delete (row) {
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.uploadUrl=sysServerUrl + 'sys/upload/attach?flag=manualImage'
    this.url = cmsServerUrl
    this.manualId = this.$route.params.manualId
  
    this.manualVersion = this.$route.params.manualVersion

    this.dataList()
    this.getVersionList()
    this.getUserList()
  }
}
</script>
<style>
  .el-upload-list__item {
   transition: none !important;
  }
</style>
