import axios from 'axios'

// const sysServerUrl = 'http://192.168.1.180:8080/cmsmgt/'
// const cmsServerUrl = 'http://192.168.1.180:8080/cmsmgt/'

// const sysServerUrl = 'http://192.168.1.143:9999/'
// const cmsServerUrl = 'http://192.168.1.143:9999/'

// const sysServerUrl = '/api/'
// const cmsServerUrl ='/api/'

const sysServerUrl = 'https://rdmt.zzrunta.com/rdmt-server/'
const cmsServerUrl = 'https://rdmt.zzrunta.com/rdmt-server/'


const tagsList = []
let catalogueList = []
const obj = {
  // catalogueData () {
  //   axios.get(sysServerUrl + 'sys/user/current/menutreee').then(res => {
  //     catalogueList = res.data.data
  //     // console.log(catalogueList)
  //   })
  // },
  getUserToken () {
  //   if (sessionStorage.token !== undefined) {
  //     axios.get(sysServerUrl + 'sys/user/current/info', {
  //       headers: { Authorization: sessionStorage.token }
  //     }).then(res => {

  //       if (res.data.code === 200) {
  //         sessionStorage.setItem('userId', res.data.data.id)
  //       }
  //     })
  //   } else {
  //     this.axios.replace('/login')
  //     // this.$router.push('/login')
  //   }
  },

  // 时间戳转时间格式
  getMyDate (str) {
    var date = new Date(str)
    var Y = date.getFullYear() + '-'
    var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
    var D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
    var time = Y + M + D
    return time
  },
  // tab
  tabsClick (name, url) {
    var tabName = name
    var tabUrl = url
    var newObj = {}
    let tabExistflag=0
    for(let i=0;i<tagsList.length;i++){
      if(tabName===tagsList[i].name){
        tabExistflag=1
        if(tabName === '手册版本'){
          tagsList[i].name = tagsList[i].name
          tagsList[i].path = tabUrl
        }else if(tabName === '数据管理'){
          tagsList[i].name = tagsList[i].name
          tagsList[i].path = tabUrl
        }
        break;
      }
    }
    if(tabExistflag===0){
      newObj = { name: tabName, path: tabUrl }
      tagsList.push(newObj)
    }
  }
}
export {
  sysServerUrl,
  cmsServerUrl,
  tagsList,
  catalogueList,
  obj
}
