<template>
  <!-- 添加或修改业务对话框 -->
  <el-dialog :title="title" :visible.sync="open" width="500px" :close-on-click-modal="false" append-to-body>
    <el-form ref="bizform" :model="bizform" label-width="80px">
      <el-form-item label="业务名称" prop="bizName">
        <el-input v-model="bizform.bizName" placeholder="请输入业务名称"/>
      </el-form-item>
      <el-form-item label="业务编码" prop="bizCode">
        <el-input v-model="bizform.bizCode" placeholder="请输入编码名称"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
    // import {getById, addBizDefine, updateBizDefine} from "@/api/funds/routecenter/bizdefine";

    export default {
        name: "testDialog",
        data() {
            return {
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 表单参数
                bizform: {}
            };
        },
        methods: {
        	// 窗口初始化方法，nextTick方法可以添加逻辑，如打开窗口时查询数据填充
            init(bizId) {
                this.open = true;
                // this.$nextTick(() => {
                //     getById(bizId).then(response => {
                //         this.bizform = response.data;
                //         this.open = true;
                //         this.title = "修改业务";
                //     });
                // });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            /** 提交按钮 */
            submitForm: function () {
                this.$refs["bizform"].validate(valid => {
                    if (valid) {
                        // if (this.bizform.id != undefined) {
                        //     updateBizDefine(this.bizform).then(response => {
                        //         if (response.data) {
                        //             this.msgSuccess("修改成功");
                        //             this.open = false;
                        //             // 调用主页面的getList方法刷新主页面
                        //             this.$parent.getList();
                        //         } else {
                        //             this.msgError(response.resultMsg);
                        //         }
                        //     });
                        // } else {
                        //     addBizDefine(this.bizform).then(response => {
                        //         if (response.data) {
                        //             this.msgSuccess("新增成功");
                        //             this.open = false;
                        //             // 调用主页面的getList方法刷新主页面
                        //             this.$parent.getList();
                        //         } else {
                        //             this.msgError(response.resultMsg);
                        //         }
                        //     });
                        // }
                    }
                });
            }
        }
    };
</script>
