import { post,  get,down } from "./manage";
import { logoApi, dictApi, userApi, 
        roleApi, menuApi, oauthApi, 
        trainApi, modelApi, projectApi, 
        manualApi, manualVersionApi, manualCatalogApi, 
        manualContentApi, manualManageApi, auditApi,fdmManualApi,
        tenantApi,accountApi,orgApi,regionApi,
        imageApi, imgDetailApi, issueApi, 
        parseApi, styleApi, styleConfigApi, 
        styleReleaseApi, auditManualApi, auditDetailApi,
        syllabusApi } from './index'
export const onLogin = (params) => post(logoApi.login, params); // 登录
export const captCha = (params) => get(logoApi.captcha, params); // 验证码
export const project = (params) => get(logoApi.project, params) ; // 全部项目
export const getUserInfo = (params) => get(logoApi.userInfo, params); // 用户信息
export const catalog = (params) => get(logoApi.catalogue, params) ; // 目录列表
export const dynamic = (params) => get(logoApi.dynamic, params) ; // 动态列表

// 数据字典
export const dictData = (params) => get(dictApi.dictData, params); // 数据字典-内容数据
export const dictType = (params) => get(dictApi.dictType, params); // 数据字典-类型数据
export const dictSearch = (params) => get(dictApi.dictSearch, params); // 数据字典-搜索
export const dictAdd = (params) => get(dictApi.dictAdd, params);// 数据字典-增加
export const dictEdit = (params) => get(dictApi.dictEdit, params); //数据字典-编辑
export const dictDel = (params) => get(dictApi.dictDel + params); // 数据字典-删除

// 用户管理
export const userData = (params) => get(userApi.userData, params);// 用户管理-内容数据
export const userRole = (params) => get(userApi.userRole, params);// 用户管理-角色信息
export const userSearch = (params) => get(userApi.userSearch, params);// 用户管理-搜索
export const userAdd = (params) => post(userApi.userAdd, params);// 用户管理-增加
export const batchDelete = (params) => post(userApi.batchDelete, params); // 用户管理-批量删除
export const rolesData = (params) => get(userApi.rolesData + params);// 用户管理-分配角色(展示)
export const assignRoles = (params) => post(userApi.assignRoles, params);  // 用户管理-分配角色(分配)
export const passwordReset = (params) => get(userApi.passwordReset + params); // 用户管理-密码重置
export const userEdit = (params) => get(userApi.userEdit, params); // 用户管理-编辑
export const userDel = (params) => get(userApi.userDel + params); // 用户管理-删除
export const downTemplate = (params) => down(userApi.downTemplate + params); // 用户管理-模板下载
export const exportUser = (params) => down(userApi.exportUser + params); // 用户管理-导出用户
export const currentUser = (params) => get(userApi.currentUser,params); // 用户管理-当前用户信息


// 角色管理
export const roleData = (params) => get(roleApi.roleData, params); //角色管理-内容数据
export const roleSearch = (params) => get(roleApi.roleSearch, params);//角色管理-搜索
export const roleAdd = (params) => post(roleApi.roleAdd, params); //角色管理-增加
export const roleEdit = (params) => post(roleApi.roleEdit, params);//角色管理-编辑
export const roleDel = (params) => get(roleApi.roleDel + params);//角色管理-删除

// 菜单管理
export const menuData = (params) => get(menuApi.menuData, params); // 菜单管理-内容数据
export const assignBut = (params) => get(menuApi.assignBut, params); // 菜单管理-分配按钮列表
export const menuAdd = (params) => post(menuApi.menuAdd, params); // 菜单管理-增加
export const menuEdit = (params) => post(menuApi.menuEdit, params);  // 菜单管理-编辑
export const menuCheck = (params) => get(menuApi.menuCheck + params); // 菜单管理-查看
export const menuDel = (params) => get(menuApi.menuDel + params); // 菜单管理-删除
export const menuRoles = (params) => get(menuApi.menuRoles + params);//菜单管理-分配按钮信息
export const menuAssign = (params) => post(menuApi.menuAssign, params);  //菜单管理-分配按钮提交

// 权限管理
export const oauthData = (params) => get(oauthApi.oauthData, params);//权限管理-内容数据
export const oauthRole = (params) => get(oauthApi.oauthRole, params);//权限管理-角色列表
export const oauthUser = (params) => get(oauthApi.oauthUser, params);//权限管理-用户列表
export const oauthSubmit = (params) => post(oauthApi.oauthSubmit, params);//权限管理-提交
export const userRefresh = (params) => post(oauthApi.userRefresh, params);//权限管理-刷新用户
export const roleRefresh = (params) => post(oauthApi.roleRefresh, params);//权限管理-刷新角色

// 租户管理
export const tenantData = (params) => get(tenantApi.tenantData, params); //租户管理-内容数据
export const tenantSearch = (params) => get(tenantApi.tenantSearch, params);//租户管理-搜索
export const tenantList = (params) => get(tenantApi.tenantList, params);// 用户管理-角色信息
export const tenantDetail = (params) => get(tenantApi.tenantDetail, params);//租户管理-查看
export const tenantAdd = (params) => post(tenantApi.tenantAdd, params); //租户管理-增加
export const tenantEdit = (params) => post(tenantApi.tenantEdit, params);//租户管理-编辑
export const tenantDel = (params) => get(tenantApi.tenantDel + params);//租户管理-删除


// 账户管理
export const accountData = (params) => get(accountApi.accountData, params);// 账户管理-内容数据
export const accountRole = (params) => get(accountApi.accountRole, params);// 账户管理-角色信息
export const accountSearch = (params) => get(accountApi.accountSearch, params);// 账户管理-搜索
export const accountAdd = (params) => post(accountApi.accountAdd, params);// 账户管理-增加
export const accountEdit = (params) => get(accountApi.accountEdit, params); // 账户管理-编辑
export const accountDel = (params) => get(accountApi.accountDel + params); // 账户管理-删除

export const accountBatchDel = (params) => post(accountApi.accountBatchDel, params); // 账户管理-批量删除
export const accountAssignRoles = (params) => post(accountApi.accountAssignRoles, params);  // 账户管理-分配角色(分配)
export const accountPasswordReset = (params) => get(accountApi.accountPasswordReset + params); // 账户管理-密码重置

// 机构管理
export const orgData = (params) => get(orgApi.orgData, params); //机构管理-内容数据
export const orgSearch = (params) => get(orgApi.orgSearch, params);//机构管理-搜索
export const orgListAll = (params) => get(orgApi.orgListAll, params);//机构管理-搜索
export const orgDetail = (params) => get(orgApi.orgDetail, params);//机构管理-查看
export const orgAdd = (params) => post(orgApi.orgAdd, params); //机构管理-增加
export const orgEdit = (params) => post(orgApi.orgEdit, params);//机构管理-编辑
export const orgDel = (params) => get(orgApi.orgDel + params);//机构管理-删除

// 区域管理
export const regionData = (params) => get(regionApi.regionData, params); //区域管理-内容数据
export const regionSearch = (params) => get(regionApi.regionSearch, params);//区域管理-搜索
export const regionListAll = (params) => get(regionApi.regionListAll, params);//区域管理-查询列表
export const regionPrincipal = (params) => get(regionApi.regionPrincipal, params);// 区域负责人-搜索
export const regionDetail = (params) => get(regionApi.regionDetail, params);//区域管理-查看
export const regionAdd = (params) => post(regionApi.regionAdd, params); //区域管理-增加
export const regionEdit = (params) => post(regionApi.regionEdit, params);//区域管理-编辑
export const regionDel = (params) => get(regionApi.regionDel + params);//区域管理-删除


//维修技术手册管理
export const fdmManualList = (params) => get(fdmManualApi.fdmManualList, params); //手册管理-内容数据
export const fdmManualQuery = (params) => get(fdmManualApi.fdmManualQuery, params);// 手册管理-搜索
export const fdmManualType = (params) => get(fdmManualApi.fdmManualType, params);// 手册管理-手册分类
export const fdmManualAdd = (params) => post(fdmManualApi.fdmManualAdd, params); //手册管理-增加
export const fdmManualEdit = (params) => post(fdmManualApi.fdmManualEdit, params);//手册管理-编辑
export const fdmManualDel = (params) => get(fdmManualApi.fdmManualDel + params);//手册管理-删除

// 车系管理
export const trainData = (params) => get(trainApi.trainData, params);//车系管理-内容数据
export const typeData = (params) => get(trainApi.typeData, params);//车系管理-节点类型
export const firmList = (params) => get(trainApi.firmList, params);//车系管理-主机厂商列表
export const trainListByTenant = (params) => get(trainApi.trainListByTenant, params);//车系管理-租户查询车系列表
export const currentInfo = (params) => post(trainApi.currentInfo + params);//车系管理-获取当前信息
export const trainAdd = (params) => post(trainApi.trainAdd, params);//车系管理-增加节点
export const trainUpdate = (params) => post(trainApi.trainUpdate, params); //车系管理-修改
export const trainDel = (params) => post(trainApi.trainDel, params); // 车系管理-删除
// 车型管理
export const modelData = (params) => get(modelApi.modelData, params); // 车型管理-内容数据
export const listTrain = (params) => get(modelApi.listTrain, params); // 车型管理-车型数据
export const modelSearch = (params) => get(modelApi.modelSearch, params); //车型管理-搜索
export const modelYearList = (params) => get(modelApi.modelYearList, params); //车型管理-年款列表
export const modelAdd = (params) => post(modelApi.modelAdd, params); //车型管理-增加
export const modelEdit = (params) => post(modelApi.modelEdit, params); // 车型管理-编辑
export const modelDel = (params) => get(modelApi.modelDel + params); //车型管理-删除
export const cfgData = (params) => get(modelApi.cfgData + params); // 车型管理-车型配置
export const delAllCfg = (params) => get(modelApi.delAllCfg + params); //车型管理-删除全部配置代码
export const delConfig = (params) => get(modelApi.delConfig + params); //车型管理-删除当前行配置代码
export const saveConfig = (params) => post(modelApi.saveConfig, params); //车型管理-车型配置保存
export const downModel = (params) => down(modelApi.downModel + params); // 车型管理-车型模板下载
export const downConfig = (params) => down(modelApi.downConfig + params); // 车型管理-配置模板下载

// 大纲管理
export const syllabusData = (params) => post(syllabusApi.syllabusData, params); // 大纲管理-内容数据
export const syllabusFirm = (params) => post(syllabusApi.syllabusFirm, params); // 大纲管理-公司列表
export const syllabusFile = (params) => post(syllabusApi.syllabusFile, params); // 大纲管理-批量上传
export const syllabusEdit = (params) => post(syllabusApi.syllabusEdit, params); // 大纲管理-节点编辑
export const syllabusDel = (params) => get(syllabusApi.syllabusDel + params); // 大纲管理-删除
// 项目管理
export const projectData = (params) => get(projectApi.projectData, params);//项目管理-内容数据
export const trainInfo = (params) => get(projectApi.trainList, params); //项目管理-车系信息
export const yearInfo = (params) => get(projectApi.yearList, params);//项目管理-年款信息
export const projectSearch = (params) => get(projectApi.projectSearch, params); //项目管理-搜索
export const projectUser = (params) => get(projectApi.projectUser, params);// 项目管理-负责人
export const projectFirm = (params) => get(projectApi.projectFirm, params); //项目管理-公司
export const projectAdd = (params) => post(projectApi.projectAdd, params); // 项目管理-增加
export const projectEdit = (params) => post(projectApi.projectEdit, params);// 项目管理-编辑
export const projectDel = (params) => get(projectApi.projectDel + params);//项目管理-删除
// 手册管理
export const manualData = (params) => get(manualApi.manualData, params); // 手册管理-内容数据
export const manualSearch = (params) => get(manualApi.manualSearch, params); // 手册管理- 搜索
export const manualUser = (params) => get(manualApi. manualUser, params); // 手册管理-用户列表
export const projectList = (params) => get(manualApi.projectList, params);  // 手册管理-项目列表
export const manualType = (params) => get(manualApi.manualType, params); // 手册管理-手册分类
export const manualAdd = (params) => post(manualApi.manualAdd, params); //手册管理-增加
export const manualEdit = (params) => post(manualApi.manualEdit, params); // 手册管理-编辑
export const manualDel = (params) => get(manualApi.manualDel + params); //手册管理-删除
// 手册管理-版本
export const versionData = (params) => get(manualVersionApi.versionData + params); // 手册管理-手册目录-内容数据
export const versionAdd = (params) => post(manualVersionApi.versionAdd , params); // 手册管理-手册目录-增加
export const versionEdit = (params) => post(manualVersionApi.versionEdit , params); // 手册管理-手册目录-编辑
export const versionDefault = (params) =>post(manualVersionApi.versionDefault , params); // 手册管理-手册目录-默认版本
export const versionDel = (params) => get(manualVersionApi.versionDel + params); // 手册管理-手册目录-删除
// 手册管理-手册目录
export const catalogData = (params) => post(manualCatalogApi.catalogData , params); //手册管理-手册目录-内容列表
export const catalogFile = (params) => post(manualCatalogApi.catalogFile , params); // 手册管理-手册目录-批量上传
export const catalogCheck = (params) => post(manualCatalogApi.catalogCheck , params); // 手册管理-手册目录-批量上传分片
export const catalogImport = (params) => get(manualCatalogApi.catalogImport , params); // 手册管理-手册目录-批量上传(成功)
export const catalogEdit = (params) => post(manualCatalogApi.catalogEdit , params); // 手册管理-手册目录-节点保存修改
export const catalogDel = (params) => get(manualCatalogApi.catalogDel + params); // 手册管理-手册目录-删除
// 手册管理-手册内容
export const contentData = (params) => post(manualContentApi.contentData , params); // 手册管理-手册内容-内容数据
export const contentImport = (params) => post(manualContentApi.contentImport , params); // 手册管理-手册内容-批量上传
export const contentDetail = (params) => get(manualContentApi.contentDetail + params); // 手册管理-手册内容-节点内容
export const contentAdd = (params) => post(manualContentApi.contentAdd , params); // 手册管理-手册内容-保存当前版本/另存为新版本
// 手册管理-数据管理
export const manageData = (params) => get(manualManageApi.manageData , params);  // 手册管理-数据管理-数据内容
export const manageFile = (params) => post(manualManageApi.manageFile , params); // 手册管理-数据管理-批量上传
export const manageProgress = (params) => post(manualManageApi.manageProgress , params); // 手册管理-数据管理-批量上传(分片)
export const manageImport = (params) => get(manualManageApi.manageImport , params);// 手册管理-数据管理-上传完成

// 审核管理
export const auditData = (params) => get(auditApi.auditData , params); // 审核管理-内容数据
export const auditDictList = (params) => get(auditApi.auditDictList , params); // 审核管理-负责人数据
export const auditSearch = (params) => get(auditApi.auditSearch , params); // 审核管理-搜索
// 审核管理-手册审核
export const auditManualData = (params) => post(auditManualApi.auditManualData , params); // 审核管理-手册审核-数据内容
export const auditPass = (params) => post(auditManualApi.auditPass , params); // 审核管理-手册审核-审核通过
export const auditReject = (params) => post(auditManualApi.auditReject , params); // 审核管理-手册审核-审核驳回
// 审核管理-审核明细
export const auditDetailData = (params) => get(auditDetailApi.auditDetailData , params); // 审核管理-审核明细-数据内容
export const auditDetailVersion = (params) => get(auditDetailApi.auditDetailVersion , params); // 审核管理-审核明细-手册版本
export const auditDetailUser = (params) => get(auditDetailApi.auditDetailUser , params); // 审核管理-审核明细-用户列表
export const auditDetailSearch = (params) => get(auditDetailApi.auditDetailSearch , params); // 审核管理-审核明细-搜索
export const auditHistory = (params) => get(auditDetailApi.auditHistory , params); // 审核管理-审核明细-历史审核
// 图片管理
export const imageData = (params) => get(imageApi.imageData , params); // 图片管理-内容数据
export const imageSearch = (params) => get(imageApi.imageSearch , params); // 图片管理-搜索
export const imageUser = (params) => get(imageApi.imageUser , params); // 图片管理-用户数据
export const imageProject = (params) => get(imageApi.imageProject , params); // 图片管理-项目数据
export const imageManualType = (params) => get(imageApi.imageManualType , params); // 图片管理-手册类型
export const imageAdd = (params) => get(imageApi.imageAdd , params); // 图片管理-增加
// 图片管理-图片明细
export const detailData = (params) => get(imgDetailApi.detailData , params); // 图片管理-图片明细-内容数据
export const detailSearch = (params) => get(imgDetailApi.detailSearch , params); // 图片管理-图片明细-搜索
export const detailVersion = (params) => get(imgDetailApi.detailVersion + params); // 图片管理-图片明细-手册版本列表
export const detailUser = (params) => get(imgDetailApi.detailUser , params); // 图片管理-图片明细-用户列表
export const detailImport = (params) => post(imgDetailApi.detailImport , params); // 图片管理-图片明细-批量导入
export const detailUpdate = (params) => post(imgDetailApi.detailUpdate , params); // 图片管理-图片明细-编辑
export const detailNotes = (params) => post(imgDetailApi.detailNotes , params); // 图片管理-图片明细-批注

// 项目管理
export const issueData = (params) => get(issueApi.issueData, params); // 项目管理- 内容数据
export const issueSearch = (params) => get(issueApi.issueSearch, params); // 项目管理- 搜索
export const issueUser = (params) => get(issueApi.issueUser, params); // 项目管理- 负责人
export const issueProject = (params) => get(issueApi.issueProject, params); // 项目管理- 项目列表
export const issueFirm = (params) => get(issueApi.issueFirm, params); // 项目管理- 公司列表
export const issueManual = (params) => get(issueApi.issueManual, params); // 项目管理- 手册分类
export const manualParseList = (params) => get(issueApi.manualParseList + params); // 项目管理- 手册解析规则
export const issueAdd = (params) => post(issueApi.issueAdd, params); // 项目管理- 新增

// 样式管理
export const styleData = (params) => get(styleApi.styleData, params); // 样式管理-内容数据
export const styleAdd = (params) => post(styleApi.styleAdd, params); // 样式管理-新增
export const styleEdit = (params) => post(styleApi.styleEdit, params); // 样式管理-编辑
export const styleDel = (params) => get(styleApi.styleDel + params); // 样式管理-删除
// 样式管理-设置
export const configData = (params) => get(styleConfigApi.configData, params); // 样式管理-设置-内容数据
export const configManualType = (params) => get(styleConfigApi.configManualType, params); // 样式管理-设置-手册分类
export const configSearch = (params) => get(styleConfigApi.configSearch, params); // 样式管理-设置-搜索
export const configParse = (params) => get(styleConfigApi.configParse, params); // 样式管理-设置-手册解析规则
export const configCondition = (params) => get(styleConfigApi.configCondition, params); // 样式管理-设置-前提条件
export const configAdd = (params) => post(styleConfigApi.configAdd, params); // 样式管理-设置-增加
export const configEdit = (params) => post(styleConfigApi.configEdit, params); // 样式管理-设置-编辑
export const configDel = (params) => get(styleConfigApi.configDel + params); // 样式管理-设置-删除
// 样式管理-发布
export const releaseData = (params) => get(styleReleaseApi.releaseData, params); // 样式管理-发布-内容数据
export const releaseSearch = (params) => get(styleReleaseApi.releaseSearch, params); // 样式管理-发布-搜索
export const releaseUser = (params) => get(styleReleaseApi.releaseUser, params); // 样式管理-发布-用户列表
export const releaseConfig = (params) => get(styleReleaseApi.releaseConfig, params); // 样式管理-发布-发布配置列表
export const releaseAdd = (params) => post(styleReleaseApi.releaseAdd, params); // 样式管理-发布-增加
export const releaseEnable = (params) => post(styleReleaseApi.releaseEnable, params); // 样式管理-发布-启动 禁止
export const releaseDel = (params) => get(styleReleaseApi.releaseDel + params); // 样式管理-发布-删除
// 解析规则
export const parseData = (params) => get(parseApi.parseData, params); // 解析规则-内容数据
export const parseSearch = (params) => get(parseApi.parseSearch, params); // 解析规则-搜索
export const parseManualType = (params) => get(parseApi.parseManualType, params); // 解析规则-手册类型
export const parseManualStyle = (params) => get(parseApi.parseManualStyle, params); // 解析规则-手册样式
export const parseUser = (params) => get(parseApi.parseUser, params); // 解析规则-用户列表
export const parseProject = (params) => get(parseApi.parseProject, params); // 解析规则-项目列表
export const parseAdd = (params) => post(parseApi.parseAdd, params); // 解析规则-增加
export const parseCheck = (params) => get(parseApi.parseCheck + params); // 解析规则-查看
export const parseEdit = (params) => get(parseApi.parseEdit, params); // 解析规则-编辑
export const parseDel = (params) => get(parseApi.parseDel + params); // 解析规则-删除
