import { sysServerUrl, cmsServerUrl } from '../assets/js/common'
const logoApi = {
  login: sysServerUrl + 'simgen-sysmgt/sys/user/login',  // 登录
  captcha: sysServerUrl + 'simgen-sysmgt/sys/captcha/get', // 验证码
  project: sysServerUrl + 'cms/project/list/all', // 全部项目
  userInfo: sysServerUrl + 'simgen-sysmgt/sys/user/getCurrentInfo', // 用户信息
  catalogue: sysServerUrl + 'simgen-sysmgt/sys/user/current/menutreee', // 目录信息
  dynamic: sysServerUrl + 'simgen-sysmgt/sys/log/dynamic/list', // 目录信息
}
// 系统管理
// 数据字典
const dictApi ={
  dictData: sysServerUrl + 'simgen-sysmgt/sys/dict/list',  // 数据字典-内容数据
  dictType: sysServerUrl + 'simgen-sysmgt/sys/dict/list/type', // 数据字典-类型数据
  dictSearch: sysServerUrl + 'simgen-sysmgt/sys/dict/list', // 数据字典-搜索
  dictAdd: sysServerUrl + 'simgen-sysmgt/sys/dict/add', // 数据字典-增加
  dictEdit: sysServerUrl + 'simgen-sysmgt/sys/dict/edit', // 数据字典-编辑
  dictDel: sysServerUrl + 'simgen-sysmgt/sys/dict/del/', // 数据字典-删除
}
// 用户管理
const userApi = {
  userData: sysServerUrl + 'simgen-sysmgt/sys/user/list', // 用户管理-内容数据
  userRole: sysServerUrl + 'simgen-sysmgt/sys/role/list/all', // 用户管理-角色信息
  userSearch: sysServerUrl + 'simgen-sysmgt/sys/user/list', // 用户管理-搜索
  userAdd: sysServerUrl + 'simgen-sysmgt/sys/user/add',// 用户管理-增加
  batchDelete: sysServerUrl + 'simgen-sysmgt/sys/user/dels/',// 用户管理-批量删除
  userInfo: sysServerUrl + 'simgen-sysmgt/sys/user/info', // 用户管理-分配角色信息(展示)
  assignRoles: sysServerUrl + 'simgen-sysmgt/sys/user/assignRole', // 用户管理-分配角色(分配)
  passwordReset: sysServerUrl + 'simgen-sysmgt/sys/user/initPsw', // 用户管理-密码重置
  userEdit: sysServerUrl + 'simgen-sysmgt/sys/user/edit', // 用户管理-编辑
  userDel: sysServerUrl + 'simgen-sysmgt/sys/user/del/', // 用户管理-删除
  downTemplate: sysServerUrl + 'simgen-sysmgt/static/excel/用户导入模板.xlsx', // 用户管理-下载模板
  exportUser: sysServerUrl + 'simgen-sysmgt/sys/user/batchExport', // 用户管理-导出用户
  currentUser: sysServerUrl + 'simgen-sysmgt/sys/user/current/info', // 用户管理-当前用户信息
}
// 角色管理
const roleApi = {
  roleData: sysServerUrl + 'simgen-sysmgt/sys/role/list', //角色管理-内容数据
  roleSearch: sysServerUrl + 'simgen-sysmgt/sys/role/list', //角色管理-搜索
  roleAdd: sysServerUrl + 'simgen-sysmgt/sys/role/add', // 角色管理-增加
  roleEdit: sysServerUrl + 'simgen-sysmgt/sys/role/edit', // 角色管理-编辑
  roleDel: sysServerUrl + 'simgen-sysmgt/sys/role/del/' // 角色管理-删除
}
// 菜单管理
const menuApi = {
  menuData: sysServerUrl + 'simgen-sysmgt/sys/menu/getMenuTree', // 菜单管理-内容数据
  assignBut: sysServerUrl + 'simgen-sysmgt/sys/permission/list/all', // 菜单管理-分配按钮列表
  menuAdd: sysServerUrl + 'simgen-sysmgt/sys/menu/add', // 菜单管理-增加
  menuEdit: sysServerUrl + 'simgen-sysmgt/sys/menu/edit', // 菜单管理-编辑
  menuCheck: sysServerUrl + 'simgen-sysmgt/sys/menu/info', // 菜单管理-查看
  menuDel: sysServerUrl + 'simgen-sysmgt/sys/menu/del', // 菜单管理-删除
  menuRoles: sysServerUrl + 'simgen-sysmgt/sys/permission/list/bymenu', //菜单管理-分配按钮信息
  menuAssign: sysServerUrl + 'simgen-sysmgt/sys/menu/assign', //菜单管理-分配按钮提交
}
// 权限管理
const oauthApi = {
  oauthData: sysServerUrl + 'simgen-sysmgt/sys/permission/list', //权限管理-内容数据
  oauthRole: sysServerUrl + 'simgen-sysmgt/sys/role/list/all', //权限管理-角色列表
  oauthUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all' ,//权限管理-用户列表
  oauthSubmit: sysServerUrl + 'simgen-sysmgt/sys/permission/assign', //权限管理-提交
  userRefresh: sysServerUrl + 'simgen-sysmgt/sys/permission/list/user', //权限管理-刷新用户
  roleRefresh: sysServerUrl + 'simgen-sysmgt/sys/permission/list/role', //权限管理-刷新角色
}

// 租户管理
const tenantApi = {
  tenantData: sysServerUrl + 'simgen-sysmgt/sys/tenant/list', //租户管理-内容数据
  tenantSearch: sysServerUrl + 'simgen-sysmgt/sys/tenant/list', //租户管理-搜索
  tenantList: sysServerUrl + 'simgen-sysmgt/sys/tenant/list/all', //租户管理-搜索
  tenantAdd: sysServerUrl + 'simgen-sysmgt/sys/tenant/add', // 租户管理-增加
  tenantEdit: sysServerUrl + 'simgen-sysmgt/sys/tenant/edit', // 租户管理-编辑
  tenantDetail: sysServerUrl + 'simgen-sysmgt/sys/user/info', // 租户管理-查看
  tenantDel: sysServerUrl + 'simgen-sysmgt/sys/tenant/del/', // 租户管理-删除
}




// 租户账户管理
const accountApi = {
  accountData: sysServerUrl + 'simgen-sysmgt/sys/tenant/account/list', // 账户管理-内容数据
  accountSearch: sysServerUrl + 'simgen-sysmgt/sys/tenant/account/list', // 账户管理-搜索
  accountAdd: sysServerUrl + 'simgen-sysmgt/sys/tenant/account/add',// 账户管理-增加
  accountEdit: sysServerUrl + 'simgen-sysmgt/sys/tenant/account/edit', // 账户管理-编辑
  accountDel: sysServerUrl + 'simgen-sysmgt/sys/tenant/account/del/', // 账户管理-删除
  
  accountBatchDel: sysServerUrl + 'simgen-sysmgt/sys/tenant/account/dels/',// 账户管理-批量删除
  accountAssignRoles: sysServerUrl + 'simgen-sysmgt/sys/tenant/account/assignRole', // 账户管理-分配角色
  accountPasswordReset: sysServerUrl + 'simgen-sysmgt/sys/tenant/account/initPsw', // 账户管理-密码重置
}


// 机构管理
const orgApi = {
  orgData: sysServerUrl + 'simgen-sysmgt/sys/org/list', //机构管理-内容数据
  orgSearch: sysServerUrl + 'simgen-sysmgt/sys/org/list', //机构管理-搜索
  orgListAll: sysServerUrl + 'simgen-sysmgt/sys/org/list/all', //机构管理-搜索
  orgAdd: sysServerUrl + 'simgen-sysmgt/sys/org/add', // 机构管理-增加
  orgEdit: sysServerUrl + 'simgen-sysmgt/sys/org/edit', // 机构管理-编辑
  orgDetail: sysServerUrl + 'simgen-sysmgt/sys/org/info', // 机构管理-查看
  orgDel: sysServerUrl + 'simgen-sysmgt/sys/org/del/', // 机构管理-删除
}


// 区域管理
const regionApi = {
  regionData: sysServerUrl + 'simgen-sysmgt/sys/region/list', //区域管理-内容数据
  regionSearch: sysServerUrl + 'simgen-sysmgt/sys/region/list', //区域管理-搜索
  regionListAll: sysServerUrl + 'simgen-sysmgt/sys/region/list/all', //区域管理-搜索
  regionPrincipal: sysServerUrl + 'simgen-sysmgt/sys/region/principal/list', //区域管理-负责人列表
  regionAdd: sysServerUrl + 'simgen-sysmgt/sys/region/add', // 区域管理-增加
  regionEdit: sysServerUrl + 'simgen-sysmgt/sys/region/edit', // 区域管理-编辑
  regionDetail: sysServerUrl + 'simgen-sysmgt/sys/region/info', // 区域管理-查看
  regionDel: sysServerUrl + 'simgen-sysmgt/sys/region/del/', // 区域管理-删除
}


// 维修技术手册管理
var fdmManualApi = {
  fdmManualList: sysServerUrl + 'simgen-fdmmgt/fdm/manual/list', // 手册管理-内容数据
  fdmManualQuery: sysServerUrl + 'simgen-fdmmgt/fdm/manual/list', // 手册管理-搜索
  fdmManualType: sysServerUrl + 'simgen-sysmgt/sys/dict/query?dictType=manualType', // 手册管理-手册分类
  fdmManualAdd: sysServerUrl + 'simgen-fdmmgt/fdm/manual/add', //手册管理-增加
  fdmManualEdit: sysServerUrl + 'simgen-fdmmgt/fdm/manual/edit', // 手册管理-编辑
  fdmManualDel: sysServerUrl + 'simgen-fdmmgt/fdm/manual/del/', //手册管理-删除
}


// 车型管理
// 车系管理
const trainApi = {
  trainData: sysServerUrl + 'simgen-sysmgt/sys/car/train/list', //车系管理-内容数据
  typeData: sysServerUrl + 'simgen-sysmgt/sys/dict/query?dictType=trainType', //车系管理-节点类型
  firmList: sysServerUrl + 'simgen-sysmgt/sys/car/train/list/firm', //车系管理-主机厂商列表
  trainListByTenant:sysServerUrl +'simgen-sysmgt/sys/car/train/list/tenant',//车系管理-租户查询车系列表
  currentInfo: sysServerUrl + 'simgen-sysmgt/sys/car/train/find', //车系管理-获取当前信息
  trainAdd: sysServerUrl + 'simgen-sysmgt/sys/car/train/addNode', //车系管理-增加节点
  trainUpdate: sysServerUrl + 'simgen-sysmgt/sys/car/train/update', //车系管理-修改
  trainDel: sysServerUrl + 'simgen-sysmgt/sys/car/train/delete', // 车系管理-删除
}
// 车型管理
const modelApi = {
  listTrain: sysServerUrl + 'simgen-sysmgt/sys/car/train/list', //车型管理-车型数据
  modelData: sysServerUrl + 'simgen-sysmgt/sys/car/model/list?modelType=1', //车型管理-内容数据
  modelSearch: sysServerUrl + 'simgen-sysmgt/sys/car/model/list?modelType=1', //车型管理-搜索
  modelYearList: sysServerUrl + 'simgen-sysmgt/sys/car/train/year/list', //项目管理-年款信息
  modelAdd: sysServerUrl + 'simgen-sysmgt/sys/car/model/add', // 车型管理-增加
  modelEdit: sysServerUrl + 'simgen-sysmgt/sys/car/model/edit',// 车型管理-编辑
  modelDel: sysServerUrl + 'simgen-sysmgt/sys/car/model/delete',//车型管理-删除
  cfgData: sysServerUrl + 'simgen-sysmgt/sys/car/model/cfglist',//车型管理-车型配置
  delAllCfg: sysServerUrl + 'simgen-sysmgt/sys/car/model/deleteAllCfg',//车型管理-删除全部配置代码
  delConfig: sysServerUrl + 'simgen-sysmgt/sys/car/model/deleteConfig',//车型管理-删除当前行配置代码
  saveConfig: sysServerUrl + 'simgen-sysmgt/sys/car/model/saveConfig', //车型管理-车型配置保存
  downModel: sysServerUrl + 'static/excel/车型导入模板.xlsx', // 用户管理-下载模板
  downConfig: sysServerUrl + 'static/excel/车型配置表.xlsx', // 用户管理-下载模板
}
// 内容管理
// 大纲管理
var syllabusApi = {
  syllabusData: cmsServerUrl + 'cms/manual/directory/getOutlineTreeList',// 大纲管理-内容数据
  syllabusFirm: sysServerUrl + 'simgen-sysmgt/sys/car/train/list/firm',// 大纲管理-公司列表
  syllabusFile: sysServerUrl + 'cms/manual/directory/importOutline',// 大纲管理-批量上传
  syllabusEdit: cmsServerUrl +  "cms/manual/directory/edit",// 大纲管理-节点编辑
  syllabusDel: cmsServerUrl +"cms/manual/directory/del/",// 大纲管理-删除
}
// 项目管理
var projectApi = {
  projectData: cmsServerUrl + 'cms/project/list',//项目管理-内容数据
  trainList: cmsServerUrl + 'simgen-sysmgt/sys/car/train/list/byfirm',//项目管理-车系信息
  yearList: cmsServerUrl + 'simgen-sysmgt/sys/car/train/year/list/bytraincode', //项目管理-年款信息
  projectSearch: cmsServerUrl + 'cms/project/list', //项目管理-搜索
  projectUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all', // 项目管理-负责人
  projectFirm: sysServerUrl + 'simgen-sysmgt/sys/car/train/list/firm', //项目管理-公司
  projectAdd: cmsServerUrl + 'cms/project/add',  // 项目管理-增加
  projectEdit: cmsServerUrl + 'cms/project/edit', // 项目管理-编辑
  projectDel: cmsServerUrl + 'cms/project/del/',//项目管理-删除
}
// 手册管理
var manualApi = {
  manualData: cmsServerUrl + 'cms/manual/list', // 手册管理-内容数据
  manualSearch: cmsServerUrl + 'cms/manual/list', // 手册管理-搜索
  manualUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all', // 手册管理-用户列表
  projectList: cmsServerUrl + 'cms/project/list/all', // 手册管理-项目列表
  manualType: sysServerUrl + 'simgen-sysmgt/sys/dict/query?dictType=manualType', // 手册管理-手册分类
  manualAdd: cmsServerUrl + 'cms/manual/add', //手册管理-增加
  manualEdit: cmsServerUrl + 'cms/manual/edit', // 手册管理-编辑
  manualDel: cmsServerUrl + 'cms/manual/del/', //手册管理-删除
}
// 手册管理-手册版本
var manualVersionApi = {
  versionData: cmsServerUrl + 'cms/manual/version/list', // 手册管理-手册目录-内容数据
  versionAdd: cmsServerUrl + 'cms/manual/version/edit', // 手册管理-手册目录-增加
  versionEdit: cmsServerUrl + 'cms/manual/version/edit', // 手册管理-手册目录-编辑
  versionDefault: cmsServerUrl + 'cms/manual/version/setDefault', // 手册管理-手册目录-默认版本
  versionDel: cmsServerUrl + 'cms/manual/version', // 手册管理-手册目录-删除
}
// 手册管理-手册目录
var manualCatalogApi = {
  catalogData: cmsServerUrl + 'cms/manual/directory/getChildrenList',//手册管理-手册目录-内容列表
  catalogFile: sysServerUrl+"sys/upload/procFile",// 手册管理-手册目录-批量上传
  catalogCheck: sysServerUrl+"sys/upload/checkProgress",// 手册管理-手册目录-批量上传分片
  catalogImport: cmsServerUrl +"cms/manual/directory/batchImport",// 手册管理-手册目录-批量上传(成功)
  catalogEdit: cmsServerUrl +  "cms/manual/directory/edit",// 手册管理-手册目录-节点保存修改
  catalogDel: cmsServerUrl + "cms/manual/directory/del/",// 手册管理-手册目录-删除
}
// 手册管理-手册内容
var  manualContentApi = {
  contentData: cmsServerUrl + 'cms/manual/directory/getChildrenList',// 手册管理-手册内容-内容数据
  contentImport: cmsServerUrl + 'cms/manual/topic/batchImport',// 手册管理-手册内容-批量上传
  contentDetail: cmsServerUrl + 'cms/manual/topic/detail',// 手册管理-手册内容-节点内容
  contentAdd: cmsServerUrl + 'cms/manual/topic/update',// 手册管理-手册内容-保存当前版本/另存为新版本
}
// 手册管理-数据管理
var manualManageApi = {
  manageData: cmsServerUrl + 'cms/manual/verify/list', // 手册管理-数据管理-数据内容
  manageFile: sysServerUrl+'sys/upload/procFile', // 手册管理-数据管理-批量上传
  manageProgress: sysServerUrl+'sys/upload/checkProgress',// 手册管理-数据管理-批量上传(分片)
  manageImport: cmsServerUrl +"cms/manual/verify/procPackage",// 手册管理-数据管理-上传完成
}

// 审核管理
var auditApi = {
  auditData: cmsServerUrl + 'cms/manual/list',// 审核管理-内容数据
  auditDictList:sysServerUrl + 'simgen-sysmgt/sys/user/list/all',// 审核管理-负责人数据
  auditSearch: cmsServerUrl + 'cms/manual/list',// 审核管理-搜索
}
// 审核管理-手册审核
var auditManualApi = {
  auditManualData: cmsServerUrl + 'cms/manual/directory/getChildrenList', // 审核管理-手册审核-数据内容
  auditPass: cmsServerUrl +  'cms/manual/audit/pass',// 审核管理-手册审核-审核通过
  auditReject: cmsServerUrl +  'cms/manual/audit/refuse',// 审核管理-手册审核-审核驳回
}
// 审核管理-审核明细
var auditDetailApi = {
  auditDetailData: cmsServerUrl + 'cms/manual/audit/list',// 审核管理-审核明细-数据内容
  auditDetailVersion: cmsServerUrl +  'cms/manual/version/list/all',// 审核管理-审核明细-手册版本
  auditDetailUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all',// 审核管理-审核明细-用户列表
  auditDetailSearch: cmsServerUrl + 'cms/manual/audit/list',// 审核管理-审核明细-搜索
  auditHistory:cmsServerUrl + "cms/manual/audit/history",// 审核管理-审核明细-历史审核
}
// 图片管理
var imageApi = {
  imageData: cmsServerUrl + 'cms/manual/list', // 图片管理-内容数据
  imageSearch: cmsServerUrl + 'cms/manual/list', // 图片管理-搜索
  imageUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all',// 图片管理-用户数据
  imageProject: cmsServerUrl + 'cms/project/list/all',// 图片管理-项目数据
  imageManualType: sysServerUrl + 'simgen-sysmgt/sys/dict/query?dictType=manualType',// 图片管理-手册类型
  imageAdd: cmsServerUrl + 'cms/manual/add', // 图片管理-增加
}
// 图片管理-图片明细
var imgDetailApi = {
  detailData: cmsServerUrl + 'cms/manual/image/list', // 图片管理-图片明细-内容数据
  detailSearch: cmsServerUrl + 'cms/manual/image/list',// 图片管理-图片明细-搜索
  detailVersion: cmsServerUrl + 'cms/manual/version/list/all',// 图片管理-图片明细-手册版本列表
  detailUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all',// 图片管理-图片明细-用户列表
  detailImport: cmsServerUrl + 'cms/manual/image/batchImport', // 图片管理-图片明细-批量导入
  detailUpdate: cmsServerUrl +  'cms/manual/image/update',// 图片管理-图片明细-编辑
  detailNotes:cmsServerUrl +  'cms/manual/image/edit',// 图片管理-图片明细-批注
}
// 项目管理
var issueApi = {
  issueData: cmsServerUrl + 'cms/project/list', // 项目管理- 内容数据
  issueSearch: cmsServerUrl + 'cms/project/list',// 项目管理- 搜索
  issueUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all',// 项目管理- 负责人
  issueProject: cmsServerUrl + 'cms/project/list/all',// 项目管理- 项目列表
  issueFirm: sysServerUrl + 'simgen-sysmgt/sys/car/train/list/firm',// 项目管理- 公司列表
  issueManual: sysServerUrl + 'simgen-sysmgt/sys/dict/query?dictType=manualType',// 项目管理- 手册分类
  manualParseList: cmsServerUrl + 'cms/manual/parse/list/all',// 项目管理- 手册解析规则
  issueAdd: cmsServerUrl + 'cms/manual/release/add',// 项目管理- 新增
}
// 样式管理
var styleApi = {
  styleData: cmsServerUrl + 'cms/manual/style/list',// 样式管理-内容数据
  styleAdd: cmsServerUrl +  "cms/manual/style/add", // 样式管理-新增
  styleEdit: cmsServerUrl +  "cms/manual/style/edit", // 样式管理-编辑
  styleDel: cmsServerUrl + 'cms/manual/style/del/',// 样式管理-删除
}
// 样式管理-设置
var styleConfigApi = {
  configData: cmsServerUrl + 'cms/manual/release/config/list', // 样式管理-设置-内容数据
  configManualType: sysServerUrl + 'simgen-sysmgt/sys/dict/query?dictType=manualType', // 样式管理-设置-手册分类
  configSearch: cmsServerUrl + 'cms/manual/release/config/list',// 样式管理-设置-搜索
  configParse: cmsServerUrl + 'cms/manual/parse/list/all',// 样式管理-设置-手册解析规则
  configCondition: cmsServerUrl + 'cms/manual/release/config/list/all',// 样式管理-设置-前提条件
  configAdd: cmsServerUrl + 'cms/manual/release/config/add',// 样式管理-设置-增加
  configEdit: cmsServerUrl + 'cms/manual/release/config/edit',// 样式管理-设置-编辑
  configDel: cmsServerUrl + 'cms/manual/release/config/del/',// 样式管理-设置-删除
}
// 样式管理-发布
var styleReleaseApi = {
  releaseData: cmsServerUrl + 'cms/manual/release/list', // 样式管理-发布-内容数据
  releaseSearch: cmsServerUrl + 'cms/manual/release/list', // 样式管理-发布-搜索
  releaseUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all', // 样式管理-发布-用户列表
  releaseConfig: cmsServerUrl + 'cms/manual/release/config/list/all', // 样式管理-发布-发布配置列表
  releaseAdd: cmsServerUrl + 'cms/manual/release/add', // 样式管理-发布-增加
  releaseEnable: cmsServerUrl + 'cms/manual/release/edit', // 样式管理-发布-启动 禁止
  releaseDel: cmsServerUrl + 'cms/manual/release/del/', // 样式管理-发布-删除
}
// 解析规则
var parseApi = {
  parseData: cmsServerUrl + 'cms/manual/parse/list', // 解析规则-内容数据
  parseSearch: cmsServerUrl + 'cms/manual/parse/list', // 解析规则-搜索
  parseManualType: sysServerUrl + 'simgen-sysmgt/sys/dict/query?dictType=manualType', // 解析规则-手册类型 
  parseManualStyle: cmsServerUrl + 'cms/manual/style/list/all?useFlag=1',// 解析规则-手册样式
  parseUser: sysServerUrl + 'simgen-sysmgt/sys/user/list/all',// 解析规则-用户列表
  parseProject: cmsServerUrl + 'cms/project/list/all',// 解析规则-项目列表
  parseAdd: cmsServerUrl + 'cms/manual/parse/add',// 解析规则-增加
  parseCheck: cmsServerUrl + 'cms/manual/info', //解析规则-查看
  parseEdit: cmsServerUrl + 'cms/manual/parse/edit',// 解析规则-编辑
  parseDel: cmsServerUrl + 'cms/manual/parse/del/', // 解析规则-删除
}

export {
  logoApi,
  dictApi, // 数据字典
  userApi, // 用户管理
  roleApi, // 角色管理
  menuApi, // 菜单管理
  oauthApi, // 权限管理
  tenantApi, // 租户管理
  accountApi, // 租户账户管理
  orgApi, // 机构管理
  regionApi, // 区域管理
  trainApi, // 车系管理
  modelApi, // 车型管理 
  fdmManualApi,//维修技术手册管理
  syllabusApi,// 大纲管理
  projectApi,// 项目管理
  manualApi,// 手册管理
  manualVersionApi,// 手册管理-手册版本
  manualCatalogApi,// 手册管理-手册目录
  manualContentApi,// 手册管理-手册内容
  manualManageApi,// 手册管理-数据管理
  auditApi,// 审核管理
  auditManualApi,// 审核管理-手册审核
  auditDetailApi,// 审核管理-审核明细
  imageApi,// 图片管理
  imgDetailApi, // 图片管理-图片明细
  issueApi,// 项目管理
  styleApi,// 样式管理
  parseApi, // 样式管理-设置
  styleConfigApi,// 样式管理-发布
  styleReleaseApi // 解析规则
}